import React, { useState } from "react";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { IconButton, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './SearchBar.css';
import SearchMapView from '../../components/SearchMapView';
import { Services } from "../../APIs/Services";

const commonStyle = {
  cursor: "pointer",
  fontSize: "14px",
  display: "flex",
  top: 20,
  background: "none",
  left: "49%",
  border: "none",
  position: "absolute",
  transform: "translate(-50%, -50%)",
};

const mobileStyle = {
  width: "90%",
  borderRadius: "10px",
  ...commonStyle,
  justifyContent: "center",
};

const desktopStyle = {
  width: "30%",
  borderRadius: "10px",
  ...commonStyle,
  justifyContent: "center",
};

const SearchBar = ({ onSearch, setNotification, onZoom ,longitude, latitude ,onResetView }) => { // Thêm onZoom vào props
  const [openSearchMap, setOpenSearchMap] = useState(false);
  const [toSearchMapText, setToSearchMapText] = useState();
  const [listMapSearch, setListMapSearch] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [to, setTo] = useState('');
  const [thua, setThua] = useState('');
  const [coordinates, setCoordinates] = useState('');

  const closeSearchMap = () => {
    setOpenSearchMap(false);
  };

  const setTextSearchMap = (event) => {
    setToSearchMapText(event.target.value);
    Services.getSearchMaps(event.target.value).then(data => {
      setListMapSearch(data.data.data);
    });
  };

  const handleOpenModal8 = () => {
    setOpenSearchMap(true); // Thiết lập openSearchMap thành true để mở SearchMapView
  };

  const handleSearch = () => {
    if (to && thua) {
      onSearch(to, thua);
    } else {
      setNotification({ message: 'Vui lòng nhập số cho cả hai ô tìm kiếm.', type: 'error' });
      console.log('Search attempted without entering both "to" and "thua".');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
const handleZoom = () => {
  // Bước 1: Chuẩn hóa đầu vào
  // Thay thế dấu phẩy dùng làm thập phân bằng dấu chấm
  // Loại bỏ dấu phẩy dùng làm phân cách nếu có
  let normalizedInput = coordinates.replace(/,/g, '.');

  // Bước 2: Trích xuất tất cả các giá trị số
  const numberPattern = /-?\d+(\.\d+)?/g;
  const matches = normalizedInput.match(numberPattern);

  if (!matches || matches.length < 2) {
    setNotification({
      message: 'Vui lòng nhập tọa độ theo định dạng "Vĩ độ, Kinh độ".',
      type: 'error',
    });
    return;
  }

  // Lấy hai số đầu tiên làm Vĩ độ và Kinh độ
  let [first, second] = matches;

  // Chuyển đổi sang số thực
  let num1 = parseFloat(first);
  let num2 = parseFloat(second);

  // Bước 3: Xác định Vĩ độ và Kinh độ dựa trên phạm vi hợp lệ
  let latitude = null;
  let longitude = null;

  const isValidLatitude = (lat) => lat >= -90 && lat <= 90;
  const isValidLongitude = (lng) => lng >= -180 && lng <= 180;

  if (isValidLatitude(num1) && isValidLongitude(num2)) {
    latitude = num1;
    longitude = num2;
  } else if (isValidLatitude(num2) && isValidLongitude(num1)) {
    // Nếu thứ tự đảo ngược
    latitude = num2;
    longitude = num1;
  } else if (isValidLatitude(num1) && isValidLatitude(num2)) {
    // Cả hai đều hợp lệ cho Vĩ độ, gán mặc định
    latitude = num1;
    longitude = num2;
    setNotification({
      message:
        'Hai giá trị nhập vào đều hợp lệ cho Vĩ độ. Đã gán số đầu tiên là Vĩ độ và số thứ hai là Kinh độ.',
      type: 'warning',
    });
  } else if (isValidLongitude(num1) && isValidLongitude(num2)) {
    // Cả hai đều hợp lệ cho Kinh độ, gán mặc định
    latitude = num1;
    longitude = num2;
    setNotification({
      message:
        'Hai giá trị nhập vào đều hợp lệ cho Kinh độ. Đã gán số đầu tiên là Vĩ độ và số thứ hai là Kinh độ.',
      type: 'warning',
    });
  } else {
    // Trường hợp không hợp lệ
    setNotification({
      message:
        'Vui lòng nhập tọa độ hợp lệ trong phạm vi Vĩ độ (-90 đến 90) và Kinh độ (-180 đến 180).',
      type: 'error',
    });
    return;
  }

  // Bước 4: Kiểm tra và thực hiện zoom
  if (latitude !== null && longitude !== null) {
    // Kiểm tra phạm vi sau khi xác định
    if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
      setNotification({ message: 'Vui lòng nhập tọa độ trong phạm vi hợp lệ.', type: 'error' });
      return;
    }

    // Thực hiện zoom nếu hàm onZoom được cung cấp
    if (onZoom) {
      onZoom(latitude, longitude);
      setDialogOpen(false);
      setCoordinates(''); // Đặt lại trường nhập liệu sau khi zoom
      setNotification({
        message: 'Đã phóng to thành công!',
        type: 'success',
      });
    } else {
      setNotification({
        message: 'Không có hàm zoom được cung cấp.',
        type: 'error',
      });
    }
  } else {
    // Thông báo lỗi nếu phân tích thất bại
    setNotification({
      message: 'Vui lòng nhập tọa độ theo định dạng "Vĩ độ, Kinh độ".',
      type: 'error',
    });
  }
};



  return (
    <div className="search-container">
      <div
        maxWidth="xl"
        className="leaflet-control"
        position="topleft"
        style={{
          ...(window.innerWidth < 768 ? mobileStyle : desktopStyle),
          width: "100%",
          padding: "10px",
          border: "none",
          top: 40,
        }}
      >


  <div
          title="Quay lại vị trí ban đầu"
          onClick={() => {
            onResetView(); // Sử dụng hàm được truyền qua props
          }}
          style={{
            width: "2.5rem",
            height: "2.5rem",
            display: "flex",
            borderRadius: "30px",
            backgroundSize: "100%", // Đặt kích thước ảnh là 50% của kích thước của nút
            position: "relative",
            top:-7,
            margin: "2px",
            cursor: "pointer", // Thêm cursor để người dùng biết có thể nhấp
            background:"none"
          }}
        >
             <div
          title="Quay lại vị trí ban đầu"
          onClick={() => {
            onResetView(); // Sử dụng hàm được truyền qua props
          }}
          style={{
            width: "2.5rem",
            height: "2.5rem",
            display: "flex",
            borderRadius: "30px",
            background: `url(../assets/icons/logo512.png) no-repeat center center`,
            backgroundSize: "100%", // Đặt kích thước ảnh là 50% của kích thước của nút
            position: "relative",
            margin: "2px",
            cursor: "pointer", // Thêm cursor để người dùng biết có thể nhấp
          }}
        ></div>
        </div>



        <button
          title="tìm tờ thửa"
          onClick={() => {
            setDialogOpen(true);
          }}
          style={{
            cursor: "pointer",
            borderRadius: "20px",
            margin: "2px",
            padding: "5px 10px",
            fontSize: "0.8rem",
            height: "30px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            border: "1px solid SpringGreen",
            top: 5,
                 background: "#fff",
            color:"black"
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
          &nbsp;
          tờ - thửa
        </button>

        <button
          title="chọn quy hoạch"
          onClick={handleOpenModal8} // Mở SearchMapView bằng cách thiết lập openSearchMap
          style={{
            cursor: "pointer",
            borderRadius: "20px",
            margin: "2px",
            padding: "5px 10px",
            fontSize: "0.8rem",
            height: "30px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            border: "1px solid DodgerBlue",
            top: 5,
            background: "#fff",
            color:"black"
          }}
        >
          &nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z" />
          </svg>
          &nbsp;
          Chọn QH
        </button>

      
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
               <DialogActions>
          <IconButton onClick={handleDialogClose} variant="contained" color="primary">❌</IconButton>
        </DialogActions>
        <DialogTitle>Chức Năng Tìm Kiếm</DialogTitle>
        <DialogContent>
          {/* Phần tìm kiếm tờ - thửa */}
          <div style={{ marginBottom: "20px" }}>
               <InputGroup style={{ width: "100%" }}>
                                    <Form.Control
                                      style={{
                                        color: "blue",
                                        background: "#ffffff",
                                        fontSize: "0.8rem",
                                        width: "30%",
                                        height: "2.5rem",
                                        fontWeight: "bold",
                                      }}
                                      className="leaflet-tooltip9"
                                      type="number"
                                      placeholder="Tờ"
                                      value={to}
                                      onChange={(e) => setTo(e.target.value)}
                                    />
                          
                                    <Form.Control
                                      style={{
                                        color: "blue",
                                        background: "#ffffff",
                                        fontSize: "0.8rem",
                                        width: "30%",
                                        height: "2.5rem",
                                        fontWeight: "bold",
                                      }}
                                      className="leaflet-tooltip9"
                                      type="number"
                                      placeholder="Thửa"
                                      value={thua}
                                      onChange={(e) => setThua(e.target.value)}
                                    />
                          
                                    <IconButton
                                      variant="primary"
                                      onClick={() => {
                                        setDialogOpen(false);
                                        handleSearch();
                                      }}
                                      style={{ color: "blue", background: "blue", fontSize: "13px" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-play-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                                      </svg>
                                    </IconButton>
                                  </InputGroup>
          </div>

          {/* Phân cách */}
          <hr />

          {/* Phần nhập tọa độ */}
          <div style={{ marginTop: "20px" }}>
    <DialogTitle>Nhập tọa độ để zoom tới vị trí</DialogTitle>
              <div style={{ marginBottom: "20px" }}>
               <InputGroup style={{ width: "100%" }}>
                                   
                                    <Form.Control
                                      style={{
                                        color: "blue",
                                        background: "#ffffff",
                                        fontSize: "0.8rem",
                                        width: "30%",
                                        height: "2.5rem",
                                        fontWeight: "bold",
                                      }}
                                      className="leaflet-tooltip9"
                                      type="text"
                                     placeholder='Vĩ độ, Kinh độ (ví dụ: "10.8231, 106.6297")'
                                     value={coordinates}
                                    onChange={(e) => setCoordinates(e.target.value)}
                                    />
                          
                                    <IconButton
                                      variant="primary"
                                      onClick={handleZoom}
                                      style={{ color: "blue", background: "blue", fontSize: "13px" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-play-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                                      </svg>
                                    </IconButton>
              </InputGroup> 
 </div>
          </div>

          {/* Hình ảnh overlay */}
          <div style={{
            position: "relative",
            backgroundImage: `url(https://guland.vn/bds/img/huong-dan-lay-toa-do.webp)`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "auto",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            borderRadius: "5px",
            justifyContent: "center"
          }}>
            <div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
              <h1>tcmap.vn</h1>
            </div>
          </div>
        </DialogContent>
 
      </Dialog>

      <SearchMapView
        listMapSearch={listMapSearch}
        mapSearch={toSearchMapText}
        open={openSearchMap}
        handleClose={closeSearchMap}
        handleMapSearchChange={setTextSearchMap}
      />
    </div>
  );
};

export default SearchBar;
