import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { jsCrop } from '../jsCrop-master/js-crop.js'
import '../jsCrop-master/js-crop.css'
import Tesseract from 'tesseract.js'
 

import Modal from '@mui/material/Modal';
import { Utils } from '../Utils/Utils.js';
import { LoadingButton } from '@mui/lab';
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
 
const style = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "100%",md: '30%'},
  minHeight: {xs: "100%",md: '100%'},
  overflow: 'scroll',
  display: "block",
  maxHeight: "80%",
  borderRadius:"20px",
  font: "15 px",
  background : "none" ,
left: {xs: "50%",md: '50%'},
top: {xs: "50%",md: '50%'},
minWidth: {xs: "80%",md: '40%'},
  };
 

  
 

export const DISTRICTS = [
  {
    value: '0',
    label: 'Quét 1',
  },
  {
    value: '1',
    label: 'Quét 2 ',
  },]
 

const scanMethods = [
  {
    value: '0',
    label: 'Quét 1',
  },
  {
    value: '1',
    label: 'Quét 2 ',
  }
];
const locationMethods = [
  {
    value: '0',
    label: 'VN2000',
  },
  {
    value: '1',
    label: 'Google',
  }
];
const switchXYs = [
 
  {
    value: '1',
    label: 'Y chuyển X',
  }
];

const DISTRICTS1 = [


  { value: '105.00000', label: 'Hà Nội', }, 
  { value: '105.75000000', label: 'TP. Hồ Chí Minh', }, 
  { value: '104.75', label: 'An Giang', }, 
{ value: '107.75', label: 'Bà Rịa - Vũng Tàu', }, 
{ value: '107.0', label: 'Bắc Giang', }, 
{ value: '106.5', label: 'Bắc Kạn', },
 { value: '105.0', label: 'Bạc Liêu', }, 
{ value: '105.5', label: 'Bắc Ninh', }, 
{ value: '105.75', label: 'Bến Tre', },
 { value: '108.25', label: 'Bình Định', },
{ value: '105.750', label: 'Bình Dương', },
 { value: '106.25', label: 'Bình Phước', }, 
{ value: '108.5', label: 'Bình Thuận', },
 { value: '104.5', label: 'Cà Mau', }, 
{ value: '105.00', label: 'Cần Thơ', }, 
{ value: '105.7500', label: 'Cao Bằng', },
 { value: '107.750', label: 'Đà Nẵng', }, 
{ value: '108.50', label: 'Đắk Lắk', }, 
{ value: '108.5000000', label: 'Đắk Nông', },
 { value: '103.0', label: 'Điện Biên', }, 
{ value: '107.7500', label: 'Đồng Nai', },
 { value: '105.000', label: 'Đồng Tháp', }, 
{ value: '108.500', label: 'Gia Lai', }, 
{ value: '105.50', label: 'Hà Giang', },
 { value: '105.0000', label: 'Hà Nam', },
{ value: '105.500', label: 'Hà Tĩnh', }, 
{ value: '105.5000', label: 'Hải Dương', }, 
{ value: '105.75000', label: 'Hải Phòng', },
 { value: '105.000000', label: 'Hậu Giang', }, 
{ value: '106.0', label: 'Hòa Bình', },
 { value: '105.50000', label: 'Hưng Yên', }, 
{ value: '108.250', label: 'Khánh Hòa', },
 { value: '104.50', label: 'Kiên Giang', }, 
{ value: '107.50', label: 'Kon Tum', },
 { value: '103.00', label: 'Lai Châu', },
 { value: '107.75000', label: 'Lâm Đồng', }, 
{ value: '107.25', label: 'Lạng Sơn', }, 
{ value: '104.750', label: 'Lào Cai', },
 { value: '105.750000', label: 'Long An', }, 
{ value: '105.500000', label: 'Nam Định', }, 
{ value: '104.7500', label: 'Nghệ An', },
{ value: '105.0000000', label: 'Ninh Bình', }, 
{ value: '108.25000', label: 'Ninh Thuận', }, 
{ value: '104.75000', label: 'Phú Thọ', }, 
{ value: '108.5000', label: 'Phú Yên', }, 
{ value: '106.00', label: 'Quảng Bình', }, 
{ value: '107.750000', label: 'Quảng Nam', },
 { value: '108.00', label: 'Quảng Ngãi', }, 
{ value: '107.7500000', label: 'Quảng Ninh', }, 
{ value: '106.250', label: 'Quảng Trị', },
 { value: '105.5000000', label: 'Sóc Trăng', }, 
{ value: '104.00', label: 'Sơn La', },
 { value: '105.50000000', label: 'Tây Ninh', },
 { value: '105.500000000', label: 'Thái Bình', }, 
{ value: '106.50', label: 'Thái Nguyên', }, 
{ value: '105.00000000', label: 'Thanh Hóa', },
 { value: '107.00', label: 'Thừa Thiên-Huế', }, 
{ value: '105.750000000000', label: 'Tiền Giang', },
{ value: '105.50000000000000000', label: 'Trà Vinh', },
 { value: '106.000', label: 'Tuyên Quang', },
 { value: '105.5000000000', label: 'Vĩnh Long', }, 
{ value: '105.000000000', label: 'Vĩnh Phúc', }, 
{ value: '104.750000', label: 'Yên Bái', }
];

const muis = [
  {
    value: '0',
    label: 'Múi 3 độ',
  },
  {
    value: '1',
    label: 'Múi 6 độ',
  }
];

export default function ScanLocationModal(props) {
  
  const [coors, setCoors] = React.useState([]);

  

  const handleCoorChange = (index, isX) => {
    return (event) => {
      setCoors(prevState => {
        const tasks = [...prevState];
        var value = event.target.value;
        value = value.replace("(", "")
        value = value.replace(")", "")
        let coors = []

        let textes = value.replace(/\./g,   ",").split(",") 
        


        if (textes.length < 3) {
          coors = textes
        } else {
          coors[0] = textes[0] + "." + textes[1]
          coors[1] = textes[2] + "." + textes[3]
        }
        console.log(coors);
        if (coors.length == 2) {
          tasks[index][0] = coors[0].trim()
          tasks[index][1] = coors[1].trim()
        } else {
          tasks[index][0] = coors[0].trim()
        }
        return tasks;
      })
    }
  };

  const handleCoorChange2 = (index, isX) => {
    return (event) => {
      setCoors(prevState => {
        const tasks = [...prevState];
        if (isX) {
          tasks[index][0] = event.target.value
        } else {
          tasks[index][1] = event.target.value
        }
        return tasks;
      })
    }
  };


  const handleAddCoor = (x, y) => {
    setCoors([...coors, [x, y]])
  };

  const handleDeleteCoor = (index) => {
    setCoors(prevState => {
      const tasks = [...prevState]; // create new array based on current tasks
      tasks.splice(index, 1); // remove task by index
      return tasks; // return altered array
    })
  };

  const [scan, setScan] = React.useState('0');

  const handleScanChange = (event) => {
    setScan(event.target.value);
  };

  const [locationMethod, setLocationMethod] = React.useState('0');

  const handleLocationChange = (event) => {
    setLocationMethod(event.target.value);
  };

  const [switchXY, setSwitchXY] = React.useState('0');

  const handleSwitchXYChange = (event) => {
    setSwitchXY(event.target.value);
  };

  const [city, setCity] = React.useState('107.75');

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const [mui, setMui] = React.useState('0');


  const [markerDistrict, setMarkerDistrict] = React.useState("");
  const [markerDes, setMarkerDes] = React.useState("");

  // const handleDistrictChange = (event) => {
  //     setMarkerDistrict(event.target.value)
  // }

  const handleDistrictChange = (selectedOption) => {
    setMarkerDistrict(selectedOption.value);
  };
  const handleMarkerDesChange = (event) => {
      setMarkerDes(event.target.value)
  }


  const [markerDistrict1, setMarkerDistrict1] = React.useState("107.75");
  const [markerDes1, setMarkerDes1] = React.useState("107.75");

  // const handleDistrictChange = (event) => {
  //     setMarkerDistrict(event.target.value)
  // }

  const handleDistrictChange1 = (selectedOption) => {
    setMarkerDistrict1(selectedOption.value);
  };
  const handleMarkerDesChange1 = (event) => {
      setMarkerDes1(event.target.value)
  }


  const handleMuiChange = (event) => {
    setMui(event.target.value);
  };

  const [file, setFile] = React.useState("");
  const [isScaning, setIsScaning] = React.useState(false);

  React.useEffect(() => {
    setFile("")
    setCoors([])
  },[props.open])

  const handleFileChange = (event) => {
    var imgCrop = document.getElementById("imageToCrop");
    setFile(URL.createObjectURL(event.target.files[0]))
    imgCrop.onload = () => {
      // no longer needed, free memory
      jsCrop.getCurrentInstance.cropper = jsCrop.initialise(document.getElementById("imageToCrop"), {
        "outputCanvas": document.getElementById("crop-result")
      });

      jsCrop.getCurrentInstance.cropper = jsCrop.initialise(document.getElementById("imageToCrop"), {
        "outputCanvas": document.getElementById("crop-result"),
        "startInCropMode": true
      });
      jsCrop.getCurrentInstance.cropper.setCropRegion(0, 0, imgCrop.offsetWidth, imgCrop.offsetHeight);
    }
  };

  const crop = () => {
    let canvas = document.getElementById("crop-result")
    setCoors([])
    canvas.toBlob(function (blob) {

      let imageURL = URL.createObjectURL(blob);

      if (Number(scan) == 0) {
        recognizeText(imageURL);
      } else {
        callAPI(blob);
      }
    }, 'image/jpeg', 1);
  }

  const recognizeText = (imageURL) => {
    // let loading = document.getElementById("loading")
    // loading.style.display = "block"
    // loading.innerHTML = "Scanning 0%"
    setIsScaning(true)
    Tesseract.recognize(
      imageURL,
      'eng',
      {
        logger: m => {
          console.log(m)
          if (m.status === "recognizing text") {
            // loading.innerHTML = "Scanning " + Number(m.progress*100).toFixed(0) + "%"
          }
        }
      }
    ).then(({ data: { text } }) => {

      //   textResult = text
      console.log(text);
      var coordinators = []
      let st1 = text.split('\n');
      st1.forEach(element => {
        let st2 = element.replace(/[^.,0123456789]/g, ' ')
        let st3 = st2.split(' ')
        st3.forEach(element2 => {
          let data = element2.trim().replace(",", ".");
          if (data != "" && data.length > 6) {
            coordinators.push(data)
          }
        })
      });
      // let outputArea = document.getElementById("output-area")
      // outputArea.style.display = "block"
      // loading.style.display = "none"
      // console.log(coordinators)
      addFields(coordinators)
      setIsScaning(false)
    })
  }

  const addFields = (coordinators) => {
    var coor = []
    var result = []
    coordinators.forEach((value, index) => {

      coor.push(value)
      if (index % 2 != 0) {
        result.push(coor)
        coor = []
      }
    })

    setCoors(result)
  }


  const callAPI = async (imageData) => {
    setIsScaning(true)
    const input = document.getElementById("imageUpload");
    console.log("Start detecting")
    const formData = new FormData();
    formData.append("file_bytes", imageData, "image.jpg");
    formData.append("apikey", "52e17b1a1e88957");
    formData.append("language", "eng");
    // let loading = document.getElementById("loading")
    // loading.style.display = "block"
    // loading.innerHTML = "Scanning"
    const response = await fetch("https://api.ocr.space/parse/image", {
      method: 'post',
      body: formData,
    })
    // console.log(response.json())
    await response.json().then(data => {
      console.log(data)
      var coordinators = []
      var text = data.ParsedResults[0].ParsedText;
      text = text.replace(" ", "\n")
      // console.log(text)
      const values = text.split("\n")
      values.forEach(element => {
        var st = element.replace(/[^.,0123456789]/g, ' ')
        st = st.replace(/ /g, '');
        console.log(st)
        if (st.length > 5 && st != "") {
          coordinators.push(st);
        }
      })
      // console.log(coordinators)
      var temp = []
      for (let index = 0; index < coordinators.length / 2; ++index) {
        temp.push(coordinators[index].replace(",", "."))
        temp.push(coordinators[Number((coordinators.length / 2).toFixed(0)) + index].replace(",", "."))
      }
      coordinators = temp
      // let outputArea = document.getElementById("output-area")
      // outputArea.style.display = "block"
      // loading.style.display = "none"
      // console.log(coordinators)
      addFields(coordinators)
      setIsScaning(false)
    })
  }

  const convertCoor = () => {

    setCoors(prevState => {
      const data = prevState
      data.forEach((item, index) => {
        data[index][0] = parseFloat(String(item[0]).replace(",", "."))
        data[index][1] = parseFloat(String(item[1]).replace(",", "."))
      })
      return data
    })

    var result = coors
    if (Number(locationMethod) == 0) {
      for (var i = 0; i < coors.length; i++) {
        var BL;
        if (Number(switchXY) == 0) {
          BL = Utils.XY2BL(coors[i][0], coors[i][1], Number(city), Number(mui));
        } else {
          BL = Utils.XY2BL(coors[i][1], coors[i][0], Number(city), Number(mui));
        }
        result[i][0] = BL[0];
        result[i][1] = BL[1];
      }
    }
    props.handleClose()
    props.handleSubmit(result)
    // drawArea()
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      
        <Box sx={style} width="100%" className= "App" 
      >
    


          <InputGroup   style={{ height: "3rem", background:"#fcb332", zIndex: 9999 ,  borderTopLeftRadius: 10, // Apply border radius directly
            borderTopRightRadius: 10, // Apply border radius directly
            borderBottomLeftRadius: 0, // Apply border radius directly
            borderBottomRightRadius: 0, }}> 
          <IconButton     onClick={props.handleClose}  style={{  top: 5, right: "5", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 

       
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '10px',
                  width: '100%',
                  background: 'none',
                }}
              >
                <InputGroup
                
                  className="old-price"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '10px',
                    flexWrap: 'nowrap',
                    background: 'none',
                    justifyContent: 'center',
                  }}
                >
                 <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '10px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                       
                       
                    }} 
                  >
                   quét góc ranh thửa đất
                  </IconButton> <br/>

                  
                  {/* &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; */}
                  {/* <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '30px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                      color:"#ffff"
                    }}
                    onClick={redirect1}
                  >
                   Hỗ trợ  check QH ( Miễn Phí)
                  </IconButton> */}
                </InputGroup>
              </div>
          


          </InputGroup>

        

 
                            

          
        

          <Box   style={{  cursor: "pointer" , color : "red" ,background : "none" ,size: "10px" ,borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "small" }}  spacing="10px"  >
              <IconButton style={{ backgroundColor: "#fff", color: "#007bff", border: "1px solid #007bff", borderRadius: "30px", padding: "10px 20px", fontSize: "16px"}} onClick={() => document.getElementById('fileInput').click()}>
                <i class="fa fa-camera-retro"></i> &nbsp; Chọn ảnh
              </IconButton><br/><br/>
            
        <Stack id="crop-area" style={{
                position: "relative",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "auto",
                height: "50vh",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                justifyContent: "center"
              }}>
                <img id="imageToCrop"  
                src={file}
                style={{
                  position: "relative",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      justifyContent: "center",
                      width: "auto",
                      height: "50vh",
                        borderRadius: "10px",
                        backgroundImage: `url(${file})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative" ,    border: '1px solid #ccc', // Có thể thêm viền tùy chọn
                }}
           
                 />
 
                 
              </Stack><br/>
              <Stack  >
              {file && (
        <canvas
          id="crop-result"
          style={{
            background: "none",
            position: "relative",
            backgroundImage: `url(${file})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "20vh",
            height: "20vh",
            borderRadius: "5px",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          width="1000"
          viewMode={2}
          scalable={false}
          zoomable={false}
          aspectRatio={16 / 9}
          guides={false}
        ></canvas>
      )}

              </Stack>

              </Box>

            
              
              
              
          <Stack direction="column" spacing="10px">
                        


                            
                            
            <Stack direction ={{xs: '100%', md: '100%' ,display: "block", }} spacing="10px">





              
               



            </Stack>
            

            <div  direction ={{xs: 'column', md: 'column',width: "100%", display: "flex", alignItems: "center",display:"flex" }}   >
  {/* <Select
    className="leaflet-tooltip9"
    style={{ flex: 1, height: "50px",background : "#fff" }}  
    id="outlined-select-currency"
    select
    placeholder="Chọn phương thức quét ảnh"
    value={scan}
    onChange={handleScanChange}
  >
    {scanMethods.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select> */}
{/* <LoadingButton className="codepro-custom-btn codepro-btn-59" loading={isScaning}
    onClick={crop} style={{ right: 35, cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px", margin: "3px", padding: "5px 10px", zIndex:999999}} >quét sổ</LoadingButton> 

      
<Select
  isSearchable={false}
  isClearable={false}
  styles={{
    control: (styles) => ({
      ...styles,
      borderRadius: "30px",
      background: "#ffff",
      border: "1px solid #00FFFF",
      color: "#008000"
      , zIndex: 99999
      
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#00FFFF" : "white", // Chọn màu nền khi lựa chọn
      color: "#008000", 
      zIndex:999999// Màu chữ
    }),
  }}
  className="leaflet-tooltip9"
  options={DISTRICTS}
  value={DISTRICTS.find((option) => option.value === markerDistrict)}
  onChange={handleDistrictChange}
  placeholder="chọn phương thức quét"
  disabled
/> */}

<div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        className="leaflet-tooltip9"
        style={{
          flex: 1,
          height: '50px',
          background: '#fff',
          borderRadius: "30px 0 0 30px", // Bo tròn cạnh trái
        }}
        id="outlined-select-currency"
        select
        placeholder="Chọn phương thức quét ảnh"
        value={scan}
        onChange={handleScanChange}
      >
        {scanMethods.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
            
          </MenuItem>
        ))}
      </TextField>
      <LoadingButton
      loading={isScaning}
      onClick={crop}
      style={{
        color: 'red',
        background: '#fcb332',
        fontSize: '1rem',
        fontWeight: '600',
        width: '50%',
        height: '50px',
        borderRadius: "0 30px 30px 0", // Bo tròn cạnh phải
        paddingLeft: '30px',  // Thêm padding bên trái
      }}
    >
      quét ảnh
    </LoadingButton>
    </div>



  {/* <LoadingButton
    loading={isScaning}
    onClick={crop}
    style={{
      color: "red",
      background: "#ffff",
      fontSize: "0.6rem",
      fontWeight: "600",
    }}
    className="codepro-custom-btn codepro-btn-59"
  >
    quét ảnh
  </LoadingButton> */}
</div>


            {/* {coors.length == 0 ? null :  */}
            <Stack direction="column" spacing="5px">
             
              <Offcanvas.Title
              style={{
              width: "100%",
              display: "flex",
              Color: "rgba(25, 253, 4, 0.253)",
              justifyContent: "center",
              display: "block",
             
            }}
          >
        
          
          </Offcanvas.Title>

          


         
         
         
         { coors.map((value, index) => {
            return<Stack key={index} direction="row" spacing={1}>
              
                             <InputGroup>
                             <Form.Control  type="tel"   onChange={handleCoorChange2(index, true)} fullWidth value={coors[index][0]} placeholder="Toạ độ X " variant="outlined" />
                             <Form.Control  type="tel"   onChange={handleCoorChange2(index, false)} fullWidth value={coors[index][1]} placeholder="Toạ độ Y " variant="outlined" />
             

                                            
                    </InputGroup>
                    <Button onClick={() => handleDeleteCoor(index)}  style={{  color : "red"  ,position : "absolute",borderRadius:"30px", margin: "1px", padding: "5px 5x", fontSize: "12px", right: "10px",  width: "-175px"}} > ❌
                             </Button>
            </Stack>
          })}





<Stack direction="row-reverse" spacing={0}>
            
          </Stack>
          <Stack direction="row-reverse" spacing={1}>
            <IconButton onClick={() => handleAddCoor("", "")}fullWidth style={{  width:"100%", color : "red" ,background : "#fcb332",borderRadius:"30px", fontsize:"0.5rem",
fontweight: "600" }} ><i class="fas fa-plus fa-fw" aria-hidden="true"></i>Thêm toạ độ</IconButton>
         
          </Stack>





          <Stack direction ={{xs: 'column', md: 'column',width: "100%", }} spacing="1%">
            
            <TextField
            style={{ borderRadius: "30px", background : "#fff"}}
            className="codepro-custom-btn codepro-btn-59"
              fullWidth
              id="outlined-select-currency"
              select
              placeholder="Chọn Khu Vực"
              value={city}
              onChange={handleCityChange}
            >
              {DISTRICTS1.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>


           

 
          </Stack>
          <Stack direction="row" spacing="1%">
            <Button disabled={coors.length < 1} onClick={convertCoor} fullWidth style={{ cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "10px" ,position : "relative",borderRadius:"30px", margin: "3px", padding: "5px 10px", fontsize:"0.8rem",
fontweight: "600" }} className="codepro-custom-btn codepro-btn-58"
                              >Xác nhận</Button>
                                <Stack direction="row" spacing={0}  >
            

              </Stack>
          </Stack>
          </Stack>
        
            {/* } */}
            </Stack>
            <input id='fileInput' onChange={handleFileChange} type="file"    width="1px" height="1px" style={{  top: "-30px", right: "-38%",  cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "10px" ,borderRadius:"20px", margin: "1rem",  fontSize: "12px"  ,height: "1px",width:"1px" }}  />
        </Box>
      </Modal>
    </div>
  );
}