import React, { useEffect,useState,useMemo } from 'react';
import './InfoPanel.css';
import PropTypes from 'prop-types';
import { GoogleMap, StreetViewPanorama, LoadScript, Marker } from "@react-google-maps/api";
 
const InfoPanel = ({ feature, setSelectedFeature, children }) => {

  const [showMap, setShowMap] = useState(false); // Trạng thái bật/tắt hiển thị bản đồ
  const toggleMap = () => {
    setShowMap(!showMap); // Đảo trạng thái hiển thị bản đồ
  };
  const [pano, setPano] = useState(null);
  // Tọa độ từ `feature.coordinates`
  const coordinates = feature?.coordinates || { lat: 0, lng: 0 }; // Giá trị mặc định nếu không có tọa độ
  const containerStyle = {
  width: "100%",
  height: "400px"
  };
  

  

   const center = useMemo(
    () => ({
      lat: feature?.coordinates?.lat || 10.762622,
      lng: feature?.coordinates?.lng || 106.660172,
    }),
    [feature?.coordinates]
  );

  const mapOptions = useMemo(
    () => ({
      mapTypeControl: false,
      disableDefaultUI: true,
      mapTypeId: "roadmap",
    }),
    []
  );

  const streetViewOptions = useMemo(
    () => ({
      fullscreenControl: true,
      addressControl: false,
      zoomControl: false,
      showRoadLabels: false,
      motionTracking: false,
      motionTrackingControl: false,
      enableCloseButton: false,
    }),
    []
  );


  const handleClose = () => {
    setSelectedFeature(null);
    setShowMap(false);
  };

  // Handle closing via Escape key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Không cần dependency 'feature' ở đây

  // Hàm copy Prompt
  const copyPrompt = () => {
    if (feature?.coordinates) {
      const url = `https://www.google.com/maps/place/${feature.coordinates.lat},${feature.coordinates.lng}/data=!3m1!1e3`;
      window.open(url);
      navigator.clipboard.writeText(url);
    }
  };

  const copyPrompt2 = () => {
    if (feature?.coordinates) {
      const directionUrl = `https://www.google.com/maps/dir/?api=1&destination=${feature.coordinates.lng},${feature.coordinates.lat}&travelmode=driving`;
      window.open(directionUrl);
      navigator.clipboard.writeText(directionUrl);
    }
  };
  const [locationName, setLocationName] = useState('');

  const fetchLocationName = async (lat, lng) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
    const data = await response.json();

    let address = "";

    // Xử lý dữ liệu để lấy tên khu vực
    let locality = "";
    const possibleLocalities = ['village', 'hamlet', 'suburb'];
    possibleLocalities.some((field) => {
      if (data.address[field]) {
        locality = data.address[field];
        return true;
      }
      return false;
    });

    let district = data.address.city_district || data.address.county || "";
    let state = data.address.state || "";

    if (locality && district && state) {
      address = `${locality} - ${district} - ${state}`;
    } else if (locality && state) {
      address = `${locality} - ${state}`;
    } else if (district && state) {
      address = `${district} - ${state}`;
    } else if (state) {
      address = state;
    }

    setLocationName(address);
  } catch (error) {
    console.log("Lỗi khi lấy thông tin địa chỉ:", error);
  }
};
useEffect(() => {
  if (feature?.coordinates) {
    const { lat, lng } = feature.coordinates;
    fetchLocationName(lat, lng);
  }
}, [feature]);

  

  
  const landTypeColors = {
 "ODT+CLN":"rgba(255,208,255,1)",
 "ONT+BHK":"rgba(255,208,255,1)",
 "ONT+CLN":"rgba(255,208,255,1)",
 "ONT+TMD":"rgba(255,208,255,1)",
 "CLN+ODT":"rgba(255,208,255,1)",
 "ONT+HNK":"rgba(255,208,255,1)",
 "ONT+LNQ":"rgba(255,208,255,1)",
 "ONT+NHK":"rgba(255,208,255,1)",
 "ONT+TMD":"rgba(255,208,255,1)",
 "ONT+NHK":"rgba(255,208,255,1)",
 "ONT+TMD":"rgba(255,208,255,1)",
 "ONT+NHK":"rgba(255,208,255,1)",
 "ONT+TMD":"rgba(255,208,255,1)",
 "ONT+NHK":"rgba(255,208,255,1)",
 "ONT+LNK":"rgba(255,208,255,1)",
   "ODT+CLN":"#ffb4ff",
   "ONT+BHK":"#ffb4ff",
   "ONT+CLN":"#ffb4ff",
   "ONT+TMD":"#ffb4ff",
   "CLN+ODT":"#ffb4ff",
   "ONT+HNK":"#ffb4ff",
   "ONT+LNQ":"#ffb4ff",
   "ONT+LNK":"#ffb4ff",
   "ODT+BHK":"#ffb4ff",
   "ODT+":"#ffb4ff",
   "ODT+":"#ffb4ff",
   "ODT+":"#ffb4ff",
   "ODT+":"#ffb4ff",
   "ODT+LUC":"#ffb4ff",
   "ODT+NTS":"#ffb4ff",
   "RPN+NHK":"rgba(190:255:30:1)",
   "ODT+PNK":"#ffb4ff",
   "ODT+LNK":"#ffb4ff",
   "ODT+":"#ffb4ff",
   "ONT+LUC":"#ffb4ff",
   "ODT+ NNP":"#ffb4ff",
   "ONT+LNK":"#ffb4ff",
   "CLN+BHK":"#ffd2a0",
   "CLN+CLN":"#ffb4ff",
   "CLN+LUC":"#ffb4ff",
   "CLN+ ODT":"#ffb4ff",
   "ODT+ CLN":"#ffb4ff",
   "ODT+CLN":"#ffb4ff",
   "ONT+":"#ffb4ff",
   "ONT+LNC":"#ffb4ff",
   "ONT+LNC":"#ffb4ff",
   "ONT+LNC":"#ffb4ff",
   "ONT+LNC":"#ffb4ff",
   "ONT+LNC":"#ffb4ff",
   "RSM+RSN":"rgba(180:255:180:1)",
   "RSM+COC":"#ffb4ff",
   "RSM+NHK":"#ffb4ff",
   "ODT+LNQ":"#ffb4ff",
   "ONT+NHK":"#ffb4ff",
   "ONT+BHK+BHK":"#ffb4ff",
   "RSX+BHK":"#fff0b4",
   "ONT+CLN+CLN":"#ffb4ff",
   "ONT+RSX":"#ffb4ff",
   "DGT+CLN":"#fff0b4",
   "BHK+BHK":"#fff0b4",
   "BHK+LUC":"#fff0b4",
   "DGT+BHK":"#fff0b4",
   "LUC+LUC":"#fffc82",
   "TON+BHK":"#ffaaa0",
   "RSX+CLN":"#fffc96",
   "DTL+BHK":"#fff0b4",
   "RSX+RSX":"#BBDDA9",
 "ONT+CLN+BHK":"ffb4ff",


  "BHK":"#fff0b4",
  "LNK+CLN":"#fff0b4",
  "ONT+CLN+LNK":"#ffb4ff",
  "ONT+CLN+LNC":"#ffb4ff",
  "LNK+LNQ":"#fff0b4",
  "LNK+LNK":"#fff0b4",
  "ONT+CLN+LNQ":"#ffb4ff",
  "LNQ+CLN":"#fff0b4",
  "LNK+LUC":"#fff0b4",
  "LNQ+BHK":"#fff0b4",
  "LNC+CLN":"#fff0b4",
  "NTD+CLN":"#fff0b4",
  "LNK+LNC":"#fff0b4",
  "LNQ+LNQ":"#fff0b4",
  "LNQ+LNC":"#fff0b4",
  "LNK+BHK":"#fff0b4",
  "LNC+LNC":"#fff0b4",
  "DNL+LNK":"#fff0b4",
  "NTD+LNC":"#fff0b4",
  "DTL":"#a0ffff",
  
     "NNP":"#ffff64",
   "SXN":"#fffc6e",
   "CHN":"#fffc78",
   "LUA":"#fffc82",
   "LUC":"#fffc8c",
   "LUK":"#fffc96",
   "LUN":"#fffcb4",
   "COC":"#F9F55B",
   "COT":"#F9F55B",
   "CON":"#F9F55B",
   "HNK":"#fff0b4",
   "BHK":"#fff0b4",
   "NHK":"#fff0b4",
   "CLN":"#ffd2a0",
   "LNC":"#ffd2a0",
   "LNQ":"#ffd2a0",
   "LNK":"#ffd2a0",
   "LNP":"#aaff32",
   "RSX":"#BBDDA9",
   "RSN":"#BBDDA9",
   "RST":"#BBDDA9",
   "RSK":"#BBDDA9",
   "RSM":"#BBDDA9",
   "RPH":"#BFD72F",
   "RPN":"#BFD72F",
   "RPT":"#BFD72F",
   "RPK":"#BFD72F",
   "RPM":"#BFD72F",
   "RDD":"#8EC850",
   "RDN":"#8EC850",
   "RDT":"#8EC850",
   "RDK":"#8EC850",
   "RDM":"#8EC850",
  "TSN":"#aaffff", 
  "TSL":"#aff",
  "NTS": "#aaffff",
   "LMU":"#fffffe",
   "NKH":"#f5ffb4",
   "PNN":"#ffff64",
   "OTC":"#ffb4ff",
   "ONT":"#ffd0ff",
   "ODT":"#ffa0ff",
   "CDG":"#ffa0aa",
   "CTS":"#FEAAA0",
   "TSC":"#FEAAA0",
   "TSK":"#FEAAA0",
   "CQP":"#ff6450",
   "CAN":"#ff5046",
   "CSK":"#ffa0aa",
   "SKK":"#faaaa0",
   "SKN":"#faaaa0",
   "SKT":"#faaaa0",
   "TMD":"#faaaa0",
   "SKC":"#faaaa0",
   "SKS":"#cdaacd",
   "SKX":"#cdaacd",
   "CCC":"#ffaaa0",
   "DGT":"#ffaa32",
      "TL":"#aff",
   "DNL":"#ffaaa0",
   "DBV":"#ffaaa0",
   "DVH":"#ffaaa0",
   "DYT":"#ffaaa0",
   "DGD":"#ffaaa0",
   "DTT":"#ffaaa0",
   "DKH":"#ffaaa0",
   "DXH":"#ffaaa0",
   "DCH":"#ffaaa0",
   "DDT":"#ffaaa0",
   "DRA":"#cdaacd",
   "DDL":"#ffaaa0",
   "DSH":"#ffaaa0",
   "DKV":"#ffaaa0",
   "DCK":"#ffaaa0",
   "DSN":"#ffa0aa",
   "DTS":"#faaaa0",
   "DNG":"#ffaaa0",
   "DSK":"#ffaaa0",
   "DHT":"#FFAAA0",
   "TTN":"#FEAAA0",
   "TON":"#ffaaa0",
   "TIN":"#ffaaa0",
   "NTD":"#d2d2d2",
   "SMN":"#a0ffff",
   "SON":"#a0ffff",
   "MNC":"#b4ffff",
   "PNK":"#ffaaa0",
   "CSD":"#fffffe",
   "BCS":"#fffffe",
   "DCS":"#fffffe",
   "NCS":"#E6E6C8",
   "MVB":"#b4ffff",
   "MVT":"#b4ffff",
   "MVR":"#b4ffff",
   "MVK":"#b4ffff",
   "NNP":"#ffff64",  
};

const defaultColor = "rgba(255,255,255,0)"; // Màu mặc định nếu không có trong map

  
const kh2003Name = {
 
    
NNP:" 1. Nhóm đất nông nghiệp  NNP Đất nông nghiệp là đất sử dụng vào mục đích sản xuất, nghiên cứu, thí nghiệm về nông nghiệp, lâm nghiệp, nuôi trồng thuỷ sản, làm muối và mục đích bảo vệ, phát triển rừng\n bao gồm đất sản xuất nông nghiệp, đất lâm nghiệp, đất nuôi trồng thuỷ sản, đất làm muối và đất nông nghiệp khác.",

SXN:"1.1 Đất sản xuất nông nghiệp \n SXN là đất nông nghiệp sử dụng vào mục đích sản xuất nông nghiệp\n bao gồm đất trồng cây hàng năm, đất trồng cây lâu năm.",

CHN:"1.1.1 Đất trồng cây hàng năm \n CHN là đất chuyên trồng các loại cây có thời gian sinh trưởng từ khi gieo trồng tới khi thu hoạch không quá một (01) năm\n kể cả đất sử dụng theo chế độ canh tác không thường xuyên theo chu kỳ, đất cỏ tự nhiên có cải tạo sử dụng vào mục đích chăn nuôi \n bao gồm đất trồng lúa, đất cỏ dùng vào chăn nuôi, đất trồng cây hàng năm khác.",

LUA :"1.1.1.1 Đất trồng lúa \n LUA là ruộng, nương rẫy trồng lúa từ một vụ trở lên hoặc trồng lúa kết hợp với sử dụng vào các mục đích khác được pháp luật cho phép nhưng trồng lúa là chính. Trường hợp đất trồng lúa nước có kết hợp nuôi trồng thủy sản thì ngoài việc thống kê theo mục đích trồng lúa nước còn phải thống kê theo mục đích phụ là nuôi trồng thủy sản. Đất trồng lúa bao gồm đất chuyên trồng lúa nước, đất trồng lúa nước còn lại, đất trồng lúa nương.",

LUC:"1.1.1.1.1 Đất chuyên trồng lúa nước \n LUC là ruộng lúa nước (gồm cả ruộng bậc thang) hàng năm cấy trồng từ hai vụ lúa trở lên, kể cả trường hợp có luân canh, xen canh với cây hàng năm khác hoặc có khó khăn đột xuất mà chỉ trồng cấy được một vụ hoặc không sử dụng trong thời gian không quá một năm.",

LUK:"1.1.1.1.2 Đất trồng lúa nước còn lại \n LUK là ruộng lúa nước (gồm cả ruộng bậc thang) hàng năm chỉ trồng một vụ lúa, kể cả trường hợp trong năm có thuận lợi mà trồng thêm một vụ lúa hoặc cây hàng năm khác, hoặc có khó khăn đột xuất mà không sử dụng trong thời gian không quá một năm.",

LUN:"1.1.1.1.3 Đất trồng lúa nương \n LUN là đất nương, rẫy (đất dốc trên đồi, núi) để trồng lúa từ một vụ trở lên, kể cả trường hợp trồng lúa không thường xuyên theo chu kỳ và trường hợp có luân canh, xen canh với cây hàng năm khác.",

COC:"1.1.1.2 Đất cỏ dùng vào chăn nuôi \n COC là đất trồng cỏ hoặc đồng cỏ, đồi cỏ tự nhiên có cải tạo để chăn nuôi gia súc.",

HNK:"1.1.1.3 Đất trồng cây hàng năm khác \n HNK là đất trồng cây hàng năm không phải đất trồng lúa và đất cỏ dùng vào chăn nuôi gồm chủ yếu để trồng mầu, hoa, cây thuốc, mía, đay, gai, cói, sả, dâu tầm, cỏ không để chăn nuôi\n gồm đất bằng trồng cây hàng năm khác và đất nương rẫy trồng cây hàng năm khác.,",

BHK:"1.1.1.3.1 Đất bằng trồng cây hàng năm khác \n BHK là đất bằng phẳng ở đồng bằng, thung lũng, cao nguyên để trồng cây hàng năm khác.",


NKH:"1.1.1.3.2 Đất nương rẫy trồng cây hàng năm khác \n NHK là đất nương, rẫy (đất dốc trên đồi, núi) để trồng cây hàng năm khác, kể cả trường hợp trồng cây hàng năm khác không thường xuyên đã thành chu kỳ.",


CLN:"1.1.2 Đất trồng cây lâu năm \n CLN là đất trồng các loại cây có thời gian sinh trưởng trên một năm từ khi gieo trồng tới khi thu hoạch \n kể cả loại cây có thời gian sinh trưởng như cây hàng năm nhưng cho thu hoạch trong nhiều năm như thanh long, chuối, dứa, nho, v.v.  Trường hợp đất trồng cây lâu năm có kết hợp nuôi trồng thủy sản, kinh doanh dịch vụ thì ngoài việc thống kê theo mục đích trồng cây lâu năm còn phải thống kê theo các mục đích phụ là nuôi trồng thủy sản, sản xuất, kinh doanh phi nông nghiệp (trường hợp sử dụng đồng thời vào cả hai mục đích phụ thì thống kê theo cả hai mục đích phụ đó).Đất trồng cây lâu năm bao gồm đất trồng cây công nghiệp lâu năm, đất trồng cây ăn quả lâu năm và đất trồng cây lâu năm khác.",


LNC:"1.1.2.1 Đất trồng cây công nghiệp lâu năm \n LNC là đất trồng cây lâu năm có sản phẩm thu hoạch không phải là gỗ để làm nguyên liệu cho sản xuất công nghiệp hoặc phải qua chế biến mới sử dụng được gồm chủ yếu là chè, cà phê, cao su, hồ tiêu, điều, ca cao, dừa, v.v.",

LNQ:"1.1.2.2 Đất trồng cây ăn quả lâu năm \n LNQ là đất trồng cây lâu năm có sản phẩm thu hoạch là quả để ăn tươi hoặc kết hợp chế biến.",

LNK:"1.1.2.3 Đất trồng cây lâu năm khác \n LNK là đất trồng cây lâu năm không phải đất trồng cây công nghiệp lâu năm và đất trồng cây ăn quả lâu năm gồm chủ yếu là đất trồng cây lấy gỗ, lấy bóng mát, tạo cảnh quan không thuộc đất lâm nghiệp, đất vườn trồng xen lẫn nhiều loại cây lâu năm hoặc cây lâu năm xen lẫn cây hàng năm mà không được công nhận là đất ở.,",

LNP:"1.2 Đất lâm nghiệp \n LNP là đất đang có rừng tự nhiên hoặc đang có rừng trồng đạt tiêu chuẩn rừng theo quy định của pháp luật về bảo vệ và phát triển rừng, đất đang khoanh nuôi để phục hồi rừng (đất đã giao, cho thuê để khoanh nuôi, bảo vệ nhằm phục hồi rừng bằng hình thức tự nhiên là chính), đất để trồng rừng mới (đất đã giao, cho thuê để trồng rừng và đất có cây rừng mới trồng chưa đạt tiêu chuẩn rừng).Trường hợp đất lâm nghiệp được phép sử dụng kết hợp nuôi trồng thủy sản, kinh doanh dịch vụ dưới tán rừng thì ngoài việc thống kê theo mục đích lâm nghiệp còn phải thống kê theo các mục đích phụ là nuôi trồng thủy sản, sản xuất, kinh doanh phi nông nghiệp (trường hợp sử dụng đồng thời vào cả hai mục đích phụ thì thống kê cả hai mục đích phụ đó). Đất lâm nghiệp bao gồm đất rừng sản xuất, đất rừng phòng hộ, đất rừng đặc dụng.",

RSX:"1.2.1 Đất rừng sản xuất \n RSX là đất sử dụng vào mục đích sản xuất lâm nghiệp theo quy định của pháp luật về bảo vệ và phát triển rừng \n bao gồm đất có rừng tự nhiên sản xuất, đất có rừng trồng sản xuất, đất khoanh nuôi phục hồi rừng sản xuất, đất trồng rừng sản xuất",
RSN:"1.2.1.1 Đất có rừng tự nhiên sản xuất \n RSN là đất rừng sản xuất có rừng tự nhiên đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",
RST:"1.2.1.2 Đất có rừng trồng sản xuất \n RST là đất rừng sản xuất có rừng do con người trồng đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",
RSK:"1.2.1.3 Đất khoanh nuôi phục hồi rừng sản xuất \n RSK là đất rừng sản xuất đã có rừng bị khai thác, chặt phá, hoả hoạn nay đã giao, cho thuê để khoanh nuôi, bảo vệ nhằm phục hồi rừng bằng hình thức tự nhiên là chính.",
RSM:"1.2.1.4 Đất trồng rừng sản xuất \n RSM là đất rừng sản xuất đã giao, cho thuê để trồng rừng và đất có cây rừng mới trồng nhưng chưa đạt tiêu chuẩn rừng.",

RPH:"1.2.2 Đất rừng phòng hộ \n RPH là đất để sử dụng vào mục đích phòng hộ đầu nguồn, bảo vệ đất, bảo vệ nguồn nước, bảo vệ môi trường sinh thái, chắn gió, chắn cát, chắn sóng ven biển theo quy định của pháp luật về bảo vệ và phát triển rừng\n bao gồm đất có rừng tự nhiên phòng hộ, đất có rừng trồng phòng hộ, đất khoanh nuôi phục hồi rừng phòng hộ, đất trồng rừng phòng hộ.",

RPN:"1.2.2.1 Đất có rừng tự nhiên phòng hộ \n RPN là đất rừng phòng hộ có rừng tự nhiên đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",
RPT:"1.2.2.2 Đất có rừng trồng phòng hộ \n RPT là đất rừng phòng hộ có rừng do con người trồng đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",

RPK:"1.2.2.3 Đất khoanh nuôi phục hồi rừng phòng hộ \n RPK là đất rừng phòng hộ đã có rừng bị khai thác, chặt phá, hoả hoạn nay đã giao, cho thuê để khoanh nuôi, bảo vệ nhằm phục hồi rừng bằng hình thức tự nhiên là chính.",
RPM:"1.2.2.4 Đất trồng rừng phòng hộ \n RPM là đất rừng phòng hộ đã giao, cho thuê để trồng rừng và đất rừng phòng hộ có cây rừng mới trồng nhưng chưa đạt tiêu chuẩn rừng.",

PDD:"1.2.3 Đất rừng đặc dụng \n RDD là đất để sử dụng vào mục đích nghiên cứu, thí nghiệm khoa học, bảo tồn thiên nhiên và đa dạng sinh học, vườn rừng quốc gia, bảo vệ di tích lịch sử, văn hoá, danh lam thắng cảnh, bảo vệ môi trường sinh thái theo quy định của pháp luật về bảo vệ và phát triển rừng\n bao gồm đất có rừng tự nhiên đặc dụng, đất có rừng trồng đặc dụng, đất khoanh nuôi phục hồi rừng đặc dụng, đất trồng rừng đặc dụng.",

RDN:"1.2.3.1 Đất có rừng tự nhiên đặc dụng \n RDN là đất rừng đặc dụng có rừng tự nhiên đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",

RDT:"1.2.3.2 Đất có rừng trồng đặc dụng \n RDT là đất rừng đặc dụng có rừng do con người trồng đạt tiêu chuẩn rừng theo pháp luật về bảo vệ và phát triển rừng.",

RDK:"1.2.3.3 Đất khoanh nuôi phục hồi rừng đặc dụng \n RDK là đất rừng đặc dụng đã có rừng bị khai thác, chặt phá, hoả hoạn nay đã giao, cho thuê để khoanh nuôi, bảo vệ nhằm phục hồi rừng bằng hình thức tự nhiên là chính.",

RDM:"1.2.3.4 Đất trồng rừng đặc dụng \n RDM là đất rừng đặc dụng đã giao, cho thuê để trồng rừng và đất rừng đặc dụng có cây rừng mới trồng nhưng chưa đạt tiêu chuẩn rừng.",

NTS:"1.3 Đất nuôi trồng thuỷ sản \n NTS là đất được sử dụng chuyên vào mục đích nuôi, trồng thuỷ sản\n bao gồm đất nuôi trồng thuỷ sản nước lợ, mặn và đất chuyên nuôi trồng thuỷ sản nước ngọt.",

TSL:"1.3.1 Đất nuôi trồng thuỷ sản nước lợ, mặn \n TSL là đất chuyên nuôi, trồng thuỷ sản sử dụng môi trường nước lợ hoặc nước mặn.",

TSL:"1.3.2 Đất nuôi trồng thuỷ sản nước ngọt \n TSN là đất có mặt nước chuyên nuôi, trồng thuỷ sản sử dụng môi trường nước ngọt.",

LMU:"1.4 Đất làm muối \n LMU là ruộng muối để sử dụng vào mục đích sản xuất muối.",

NKH:"1.5 Đất nông nghiệp khác \n NKH là đất tại nông thôn sử dụng để xây dựng nhà kính và các loại nhà khác phục vụ mục đích trồng trọt kể cả các hình thức trồng trọt không trực tiếp trên đất\n xây dựng chuồng trại chăn nuôi gia súc, gia cầm và các loại động vật khác được pháp luật cho phép\n xây dựng trạm, trại nghiên cứu thí nghiệm nông nghiệp, lâm nghiệp, diêm nghiệp, thủy sản, xây dựng cơ sở ươm tạo cây giống, con giống\n xây dựng kho, nhà của hộ gia đình, cá nhân để chứa nông sản, thuốc bảo vệ thực vật, phân bón, máy móc, công cụ sản xuât nông nghiệp.",


PNN:" 2. Đất phi nông nghiệp Đất phi nông nghiệp \n PNN là đất đang được sử dụng không thuộc nhóm đất nông nghiệp\n bao gồm đất ở, đất chuyên dùng, đất tôn giáo, tín ngưỡng, đất nghĩa trang, nghĩa địa\n đất sông, ngòi, kênh, rạch, suối và mặt nước chuyên dùng, đất phi nông nghiệp khác",


OTC:"2.1 Đất ở \n OTC là đất để xây dựng nhà ở, xây dựng các công trình phục vụ cho đời sống\n đất vườn, ao gắn liền với nhà ở trong cùng một thửa đất thuộc khu dân cư (kể cả trường hợp vườn, ao gắn liền với nhà ở riêng lẻ) đã được công nhận là đất ở. Trường hợp thửa đất có vườn, ao gắn liền với nhà ở đang sử dụng nhưng chưa được cấp giấy chứng nhận quyền sử dụng đất thì tạm thời xác định diện tích đất ở bằng hạn mức giao đất ở mới do Uỷ ban nhân dân cấp tỉnh quy định. Trường hợp đất ở có kết hợp sử dụng vào mục đích sản xuất, kinh doanh phi nông nghiệp thì ngoài việc thống kê theo mục đích đất ở còn phải thống kê theo mục đích phụ là đất sản xuất, kinh doanh phi nông nghiệp. Đất ở bao gồm đất ở tại nông thôn, đất ở tại đô thị.",



ONT:"2.1.1 Đất ở tại nông thôn \n ONT là đất ở thuộc phạm vi địa giới hành chính các xã",

ODT:"2.1.2 Đất ở tại đô thị \n ODT là đất ở thuộc phạm vi địa giới hành chính các phường, thị trấn.",


CDG:"2.2 Đất chuyên dùng \n CDG bao gồm đất trụ sở cơ quan, công trình sự nghiệp\n đất quốc phòng, an ninh\n đất sản xuất, kinh doanh phi nông nghiệp\n đất có mục đích công cộng.",


CTS:"2.2.1 Đất trụ sở cơ quan, công trình sự nghiệp \n CTS là đất xây dựng trụ sở của cơ quan, tổ chức không phải là tổ chức kinh tế và đất xây dựng các công trình sự nghiệp. Đất trụ sở cơ quan, công trình sự nghiệp bao gồm đất trụ sở cơ quan, công trình sự nghiệp của Nhà nước và đất trụ sở khác",


TSC:"2.2.1.1 Đất trụ sở cơ quan, công trình sự nghiệp của Nhà nước \n TSC là đất xây dựng trụ sở của các cơ quan nhà nước, tổ chức chính trị, tổ chức chính trị – xã hội, tổ chức sự nghiệp công và đất xây dựng công trình sự nghiệp của Nhà nước (trừ các công trình sự nghiệp thuộc lĩnh vực văn hóa, y tế, giáo dục – đào tạo, thể dục – thể thao, nghiên cứu khoa học và công nghệ, môi trường, xã hội đã thống kê vào loại đất có mục đích công cộng)",


TSK:"2.2.1.2 Đất trụ sở khác \n TSK là đất xây dựng trụ sở của các tổ chức xã hội, tổ chức xã hội – nghề nghiệp.",

CQP:"2.2.2 Đất quốc phòng \n CQP là đất sử dụng vào mục đích quốc phòng bao gồm: đất sử dụng làm nơi đóng quân của quân đội\n đất sử dụng làm căn cứ quân sự\n đất sử dụng làm công trình phòng thủ quốc gia, trận địa và công trình đặc biệt về quốc phòng\n đất sử dụng làm ga, cảng quân sự\n đất xây dựng công trình công nghiệp, khoa học, kỹ thuật phục vụ quốc phòng\n đất sử dụng làm kho tàng quân sự\n đất làm trường bắn, thao trường, bãi thử vũ khí, bãi huỷ vũ khí, nhà trường, bệnh viện, nhà an dưỡng, làm nhà công vụ của quân đội\n đất làm trại giam giữ, cơ sở giáo dục, trường giáo dưỡng do quân đội quản lý và đất sử dụng xây dựng các công trình quốc phòng khác do Chính phủ quy định.  Trường hợp đất quốc phòng được phép kết hợp sử dụng vào các mục đích phụ gồm sản xuất nông nghiệp, lâm nghiệp, nuôi trồng thủy sản, sản xuất, kinh doanh phi nông nghiệp thì ngoài việc thống kê vào mục đích quốc phòng còn phải thống kê theo mục đích phụ là đất sản xuất nông nghiệp, đất lâm nghiệp, đất sản xuất, kinh doanh phi nông nghiệp.",


CAN:"2.2.3 Đất an ninh \n CAN là đất sử dụng vào mục đích an ninh bao gồm: đất sử dụng làm nơi đóng quân của công an\n đất xây dựng công trình công nghiệp, khoa học, kỹ thuật, kho tàng, trường bắn, thao trường, nhà trường, bệnh viện, nhà an dưỡng, trại giam giữ, cơ sở giáo dục, trường giáo dưỡng do công an quản lý và đất sử dụng vào việc xây dựng các công trình an ninh khác do Chính phủ quy định. Trường hợp đất an ninh được phép kết hợp sử dụng vào các mục đích phụ gồm sản xuất nông nghiệp, lâm nghiệp, nuôi trồng thủy sản, sản xuất, kinh doanh phi nông nghiệp thì ngoài việc thống kê vào mục đích an ninh còn phải thống kê theo mục đích phụ là đất sản xuất nông nghiệp, đất lâm nghiệp, đất sản xuất, kinh doanh phi nông nghiệp",

CSK:"2.2.4 Đất sản xuất, kinh doanh phi nông nghiệp \n CSK là đất sử dụng vào mục đích sản xuất công nghiệp, tiểu công nghiệp, thủ công nghiệp và kinh doanh, dịch vụ\n bao gồm đất khu công nghiệp\n đất cơ sở sản xuất, kinh doanh\n đất cho hoạt động khoáng sản\n đất sản xuất vật liệu xây dựng, gốm sứ",

SKK:"2.2.4.1 Đất khu công nghiệp \n SKK là đất để xây dựng cụm công nghiệp, khu công nghiệp, khu chế xuất và các khu sản xuất, kinh doanh tập trung khác có cùng chế độ sử dụng đất.",

SKC:"2.2.4.2 Đất cơ sở sản xuất, kinh doanh \n SKC là đất làm mặt bằng để xây dựng cơ sở sản xuất công nghiệp, tiểu công nghiệp, thủ công nghiệp nằm ngoài các cụm công nghiệp, khu công nghiệp, khu chế xuất và các khu sản xuất, kinh doanh tập trung\n đất các cơ sở dịch vụ, kinh doanh, thương mại và các công trình khác phục vụ cho sản xuất, kinh doanh phi nông nghiệp (kể cả trụ sở, văn phòng đại diện, sân kho và nhà kho của tổ chức kinh tế)",

SKS:"2.2.4.3 Đất sử dụng cho hoạt động khoáng sản \n SKS là đất để thăm dò, khai thác, chế biến khoáng sản gắn liền với khu vực khai thác\n trừ khoáng sản là đất, đá, cát, sỏi sử dụng để sản xuất vật liệu xây dựng, làm đồ gốm, sứ, thuỷ tinh",

SKX:"2.2.4.4 Đất sản xuất vật liệu xây dựng, gốm sứ \n SKX là đất để khai thác nguyên liệu đất, đá, cát, sỏi\n đất làm mặt bằng chế biến, sản xuất vật liệu xây dựng, làm đồ gốm, sứ, thủy tinh gắn liền với khu vực khai thác nguyên liệu",

CCC:"2.2.5 Đất có mục đích công cộng \n CCC là đất sử dụng vào mục đích xây dựng công trình, hệ thống hạ tầng phục vụ nhu cầu hoạt động chung của cộng đồng\n bao gồm đất giao thông, đất thuỷ lợi, đất công trình năng lượng, đất công trình bưu chính viễn thông, đất cơ sở văn hoá, đất cơ sở y tế, đất cơ sở giáo dục – đào tạo, đất cơ sở thể dục – thể thao, đất cơ sở nghiên cứu khoa học, đất cơ sở dịch vụ xã hội, đất chợ, đất di tích lịch sử – văn hoá, đất danh lam thắng cảnh, đất bãi thải, xử lý chất thải",

DGT:"2.2.5.1 Đất giao thông \n DGT là đất sử dụng vào mục đích xây dựng các công trình giao thông bao gồm: đường thuỷ, đường sắt, đường tàu điện, đường bộ (kể cả đường tránh, đường cứu nạn, đường trong khu dân cư và đường trên đồng ruộng phục vụ nhu cầu đi lại chung của mọi người, cầu, cống, kè, vỉa hè, tường chắn, hệ thống thoát nước, hệ thống thông tin, tín hiệu, hệ thống cấp điện phục vụ cho giao thông đường sắt, đường tàu điện, đường bộ, đường thủy và đường hàng không, hành lang bảo vệ an toàn công trình giao thông mà phải thu hồi đất)\n điểm dừng xe, điểm đón trả khách, trạm thu phí giao thông, bến phà, bến ô tô, bãi đỗ xe, ga đường sắt, cảng cá, cảng đường thuỷ, cảng hàng không (kể cả khu vực cất, hạ cánh và bãi đỗ tàu bay\n cơ sở kinh doanh dịch vụ như văn phòng, nhà làm việc, nhà kho, sân kho, cơ sở chế biến thức ăn phục vụ hành khách, cơ sở sản xuất – sửa chữa – bảo dưỡng phương tiện giao thông, khách sạn, nhà nghỉ, nhà hàng thuộc phạm vi cảng hàng không, cảng đường thuỷ, ga đường sắt, ga tàu điện, bến xe ô tô). Đối với các công trình giao thông ngầm dưới lòng đất hoặc trên không mà không làm ảnh hưởng đến việc sử dụng đất trên bề mặt (không phải thu hồi đất để giao xây dựng công trình giao thông) thì không thống kê vào đất giao thông",

DTL:"2.2.5.2 Đất thuỷ lợi \n DTL là đất sử dụng vào mục đích xây dựng các công trình thủy lợi bao gồm: hệ thống dẫn nước để cấp nước, thoát nước, tưới nước, tiêu nước phục vụ cho nhu cầu sinh hoạt, sản xuất, dịch vụ (kể cả bể chứa nước, giếng nước sinh hoạt của cộng đồng, hành lang bảo vệ công trình thuỷ lợi mà phải thu hồi đất)\n các công trình thuỷ lợi đầu mối như nhà máy nước, trạm bơm, trạm điều hành, trạm xử lý nước thải (kể cả nhà làm việc, nhà kho, cơ sở sản xuất – sửa chữa – bảo dưỡng công trình thuỷ lợi thuộc phạm vi công trình đầu mối)\n đê, kè, cống, đập và hồ chứa nước phục vụ mục đích thủy lợi là chính. Đối với các công trình thủy lợi ngầm dưới lòng đất hoặc trên không mà không làm ảnh hưởng đến việc sử dụng đất trên bề mặt (không phải thu hồi đất để giao xây dựng công trình thuỷ lợi) thì không thống kê vào đất thủy lợi. Trường hợp đất công trình thủy lợi có kết hợp sử dụng vào mục đích nuôi trồng thủy sản, kinh doanh – dịch vụ du lịch thì ngoài việc thống kê vào mục đích thuỷ lợi còn phải thống kê theo mục đích phụ là đất nuôi trồng thủy sản và đất sản xuất, kinh doanh phi nông nghiệp",

DNL:"2.2.5.3 Đất công trình năng lượng \n DNL là đất sử dụng vào mục đích xây dựng công trình về năng lượng bao gồm: nhà máy điện và các công trình phụ trợ của nhà máy điện như bãi để nguyên liệu, nhiên liệu, chất thải, trạm biến thế, hệ thống làm mát, nhà điều hành…\n công trình đập, kè, hồ chứa nước, đường dẫn nước phục vụ chủ yếu cho nhà máy thuỷ điện\n hệ thống tải điện như cột điện, đường dây tải điện, trạm biến áp\n hệ thống đường ống dẫn, kho chứa, trạm bơm xăng, dầu, khí (kể cả hành lang bảo vệ an toàn công trình năng lượng mà phải thu hồi đất)\n cơ sở khai thác, cơ sở chế biến xăng, dầu, khí\n các công trình kinh doanh dịch vụ như văn phòng, nhà làm việc, cơ sở sản xuất – sửa chữa – bảo dưỡng công trình, thiết bị thuộc phạm vi nhà máy điện và cơ sở khai thác, cơ sở chế biến xăng, dầu, khí. Đối với các công trình năng lượng ngầm dưới lòng đất hoặc trên không mà không làm ảnh hưởng đến việc sử dụng đất trên bề mặt (không phải thu hồi đất để giao xây dựng công trình năng lượng) thì không thống kê vào đất công trình năng lượng. Trường hợp đất công trình năng lượng có kết hợp sử dụng vào mục đích nuôi trồng thủy sản, kinh doanh – dịch vụ du lịch thì ngoài việc thống kê vào mục đích năng lượng còn phải thống kê mục đích phụ là đất nuôi trồng thủy sản và đất sản xuất, kinh doanh phi nông nghiệp",
DBV:"2.2.5.4 Đất công trình bưu chính viễn thông \n DBV là đất sử dụng vào mục đích xây dựng công trình về bưu chính, viễn thông gồm: hệ thống cột và đường cáp truyền thông tin\n các trạm thu – phát, xử lý tín hiệu viễn thông (kể cả hành lang bảo vệ an toàn công trình bưu chính, viễn thông mà phải thu hồi đất)\n cơ sở giao dịch với khách hàng\n các công trình kinh doanh dịch vụ như văn phòng, nhà làm việc, cơ sở sản xuất – sửa chữa – bảo dưỡng công trình, thiết bị thuộc phạm vi công trình bưu chính, viễn thông\n điểm bưu điện – văn hoá xã . Đối với các công trình bưu chính viễn thông ngầm dưới lòng đất hoặc trên không mà không làm ảnh hưởng đến việc sử dụng đất trên bề mặt (không phải thu hồi đất để giao xây dựng công trình bưu chính viễn thông) thì không thống kê vào đất công trình bưu chính viễn thông.",

DVH:"2.2.5.5 Đất cơ sở văn hoá \n DVH là đất sử dụng vào mục đích xây dựng các công trình về văn hóa bao gồm: trụ sở của cơ quan thông tấn, báo chí, đài phát thanh, đài truyền hình, công viên, vườn hoa, khu vui chơi giải trí, quảng trường, tượng đài, bia tưởng niệm, nhà văn hoá, nhà bảo tàng, nhà triển lãm, thư viện, rạp chiếu phim, rạp xiếc, nhà hát, câu lạc bộ, cơ sở sáng tác văn học, cơ sở sáng tác nghệ thuật, nhà trưng bày tác phẩm nghệ thuật, trụ sở của các đoàn nghệ thuật, nhà bán sách, báo, văn hoá phẩm và các công trình văn hoá khác.",
DYT:"2.2.5.6 Đất cơ sở y tế \n DYT là đất sử dụng vào mục đích xây dựng các công trình về y tế bao gồm: bệnh viện, nhà hộ sinh, cơ sở khám bệnh, chữa bệnh, cơ sở phục hồi chức năng\n nhà an dưỡng, cơ sở điều trị cho người bị nhiễm HIV/AIDS và các cơ sở y tế khác được nhà nước cho phép hoạt động\n kể cả phần diện tích để làm nơi kinh doanh, dịch vụ như phòng khám, chữa bệnh chất lượng cao theo yêu cầu, nhà bán thuốc, nhà nghỉ, nhà hàng, bãi gửi xe có thu tiền thuộc phạm vi cơ sở y tế",
DGD:"2.2.5.7 Đất cơ sở giáo dục – đào tạo \n DGD là đất sử dụng vào mục đích xây dựng các công trình phục vụ giáo dục – đào tạo bao gồm: nhà trẻ, trường mẫu giáo, trường phổ thông, trường trung học chuyên nghiệp, trường cao đẳng, trường đại học, học viện, cơ sở dạy nghề và các cơ sở giáo dục – đào tạo khác\n kể cả phần diện tích làm ký túc xá cho học sinh, sinh viên, làm nơi bán đồ dùng học tập, nhà hàng, bãi đỗ xe và các khu chức năng khác thuộc phạm vi cơ sở giáo dục – đào tạo",

DTT:"2.2.5.8 Đất cơ sở thể dục – thể thao \n DTT là đất sử dụng vào mục đích xây dựng các công trình phục vụ thể dục – thể thao bao gồm: sân vận động, sân gôn, bể bơi, cơ sở tập luyện, huấn luyện, thi đấu thể dục – thể thao, kể cả phần diện tích làm nơi bán vé, bán đồ lưu niệm, bán dụng cụ thể dục thể thao, khách sạn, nhà nghỉ, nhà hàng, bãi đỗ xe và các khu chức năng khác thuộc phạm vi cơ sở thể dục – thể thao.",

DKH:"2.2.5.9 Đất cơ sở nghiên cứu khoa học \n DKH là đất sử dụng vào mục đích xây dựng các công trình nghiên cứu khoa học và công nghệ (trừ đất xây dựng trạm, trại nghiên cứu thí nghiệm về nông nghiệp, lâm nghiệp, diêm nghiệp, thủy sản)",

DXH:"2.2.5.10 Đất cơ sở dịch vụ về xã hội \n DXH là đất sử dụng vào mục đích xây dựng các công trình dịch vụ xã hội gồm: khu nuôi dưỡng người già, trẻ em có hoàn cảnh khó khăn\n trại giáo dưỡng, cơ sở cai nghiện ma túy\n cơ sở chuyên dịch vụ tổ chức lễ cưới\n nhà tang lễ, nhà hoả táng và các cơ sở dịch vụ xã hội khác được nhà nước cho phép hoạt động.",

DCH:"2.2.5.11 Đất chợ \n DCH là đất sử dụng vào mục đích xây dựng chợ (không bao gồm đất sử dụng làm trung tâm thương mại, siêu thị).",
DDT:"2.2.2.12 Đất có di tích, danh thắng \n DDT là đất có các di tích lịch sử – văn hoá, danh lam, thắng cảnh đã được Nhà nước xếp hạng hoặc được Uỷ ban nhân dân tỉnh, thành phố trực thuộc Trung ương quyết định bảo vệ\n kể cả diện tích mặt nước, vườn cây gắn liền với công trình di tích lịch sử – văn hóa và diện tích làm nơi bán vé, nhà hàng, nhà bán đồ lưu niệm, bãi đỗ xe, khách sạn, nhà nghỉ và các công trình khác phục vụ tham quan du lịch nằm trong khu di tích lịch sử – văn hoá, danh lam thắng cảnh. Đối với diện tích đất có rừng thuộc các khu di tích lịch sử, văn hoá, danh lam thắng cảnh thì thống kê vào diện tích đất rừng đặc dụng.",

DRA:"2.2.5.13 Đất bãi thải, xử lý chất thải \n DRA là đất sử dụng làm bãi để chất thải, rác thải, làm khu xử lý chất thải, rác thải nằm ngoài các khu công nghiệp.",

TTN:"2.3 Đất tôn giáo, tín ngưỡng \n TTN là đất có các công trình sử dụng cho mục đích tôn giáo, tín ngưỡng dân gian\n bao gồm đất tôn giáo và đất tín ngưỡng.",

TON:"2.3.1 Đất tôn giáo \n TON là đất có các công trình tôn giáo gồm chùa, nhà thờ, thánh thất, thánh đường, tu viện, trường đào tạo riêng của tôn giáo\n đất trụ sở của tổ chức tôn giáo và các cơ sở khác của tôn giáo được Nhà nước cho phép hoạt động",


TIN:"2.3.2 Đất tín ngưỡng \n TIN là đất có các công trình tín ngưỡng dân gian gồm đình, đền, miếu, am, từ đường, nhà thờ họ.",

NTD:"2.4 Đất nghĩa trang, nghĩa địa \n NTD là đất để làm nơi mai táng tập trung.",

SMN:"2.5 Đất sông suối và mặt nước chuyên dùng \n SMN là đất có mặt nước mà không sử dụng chuyên vào các mục đích nuôi trồng thuỷ sản, thuỷ điện, thuỷ lợi.Trường hợp đất sông suối và mặt nước chuyên dùng có kết hợp sử dụng vào mục đích nuôi trồng thủy sản, kinh doanh – dịch vụ du lịch thì ngoài việc thống kê vào mục đích chuyên dùng còn phải thống kê theo mục đích phụ là đất nuôi trồng thủy sản và đất sản xuất, kinh doanh phi nông nghiệp. Đất sông suối và mặt nước chuyên dùng bao gồm: đất sông, ngòi, kênh, rạch, suối và đất có mặt nước chuyên dùng.",

SON:"2.5.1 Đất sông, ngòi, kênh, rạch, suối \n SON là đất có mặt nước của các đối tượng thuỷ văn dạng sông không thể tạo ranh giới khép kín để hình thành thửa đất, không sử dụng chuyên cho mục đích nuôi trồng thuỷ sản, thuỷ điện, thuỷ lợi",

MNC:"2.5.2 Đất có mặt nước chuyên dùng \n MNC là đất có mặt nước của các đối tượng thuỷ văn dạng ao, hồ, đầm có thể tạo ranh giới khép kín để hình thành thửa đất, không sử dụng chuyên vào mục đích chuyên nuôi trồng thuỷ sản, thuỷ điện, thuỷ lợi. Trường hợp sông cắt ngang các hồ chứa nước thì cần xác định phần diện tích sông theo dòng chảy liên tục\n diện tích hồ sẽ không gồm phần đã tính vào diện tích sông).",

PNK:"2.6 Đất phi nông nghiệp khác \n PNK bao gồm đất làm nhà nghỉ, lán trại, nhà tạm (không phải là nhà ở) để người lao động sử dụng tại các trang trại ở nông thôn\n đất để xây dựng cơ sở sản xuất dịch vụ nông, lâm, ngư nghiệp tại đô thị như xây dựng nhà kính và các loại nhà khác phục vụ mục đích trồng trọt (kể cả các hình thức trồng trọt không trực tiếp trên đất), xây dựng chuồng trại chăn nuôi gia súc, gia cầm và các loại động vật khác được pháp luật cho phép, xây dựng trạm, trại nghiên cứu thí nghiệm nông nghiệp, lâm nghiệp, thủy sản, xây dựng cơ sở ươm tạo cây giống, con giống, xây dựng kho, nhà của hộ gia đình, cá nhân để chứa nông sản, thuốc bảo vệ thực vật, phân bón, máy móc, công cụ sản xuất nông nghiệp.",

CSD:" 3. Đất chưa sử dụng Đất chưa sử dụng \n CSD là đất chưa xác định mục đích sử dụng\n bao gồm đất bằng chưa sử dụng, đất đồi núi chưa sử dụng, núi đá không có rừng cây.",

BSC:"3.1 Đất bằng chưa sử dụng \n BCS là đất chưa sử dụng tại vùng bằng phẳng ở đồng bằng, thung lũng, cao nguyên.",


DCS:"3.2 Đất đồi núi chưa sử dụng \n DCS  là đất chưa sử dụng trên vùng đồi, núi.",

NCS:"3.3 Núi đá không có rừng cây \n NCS là đất chưa sử dụng ở dạng núi đá mà trên đó không có rừng cây.",

MVB:"4. Đất có mặt nước ven biển  Đất có mặt nước ven biển \n MVB là đất mặt biển ngoài đường mép nước, không thuộc địa giới hành chính của tỉnh, đang được sử dụng\n bao gồm đất mặt nước ven biển nuôi trồng thuỷ sản, đất mặt nước ven biển có rừng, đất mặt nước ven biển có mục đích khác.",

MVT:"4.1 Đất mặt nước ven biển nuôi trồng thuỷ sản \n MVT là đất có mặt nước ven biển không thuộc địa giới hành chính của tỉnh, đang sử dụng để nuôi trồng thủy sản.",
MVR:"4.2 Đất mặt nước ven biển có rừng \n MVR là đất có mặt nước ven biển không thuộc địa giới hành chính đang có rừng ngập mặn.",

MNK:"4.3 Đất mặt nước ven biển có mục đích khác \n MVK là đất có mặt nước ven biển không thuộc địa giới hành chính đang sử dụng làm nơi tắm biển, du lịch biển, nơi neo đậu tầu thuyền, nơi thăm dò, khai thác, chế biến khoáng sản biển.",



  };

  const selectedKey = "NNP";

  return (
 
   <div className={`info-panel ${feature ? 'visible' : 'hidden'}`}>
  <div className="info-tab-bar">
    <div className="scrollable-buttons">
      <button className="action-button" onClick={copyPrompt2}>
        <i className="fa fa-car" aria-hidden="true"></i>&nbsp; Dẫn đường
      </button>
      <button className="action-button" onClick={copyPrompt}>
        <i className="fa fa-location-arrow" aria-hidden="true"></i>&nbsp; Chia sẻ vị trí
      </button>
      <button className="action-button" onClick={toggleMap}>
        {showMap ? "Tắt 360 độ" : "Hiển thị 360"}
      </button>
    </div>
    <span id="close-info-panel" className="close-button" onClick={handleClose}>
     ❌
    </span>
  </div>

 

 

{showMap && (
   <LoadScript googleMapsApiKey="AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk" libraries={["places"]}>
  <div style={{ position: "relative", zIndex: 0 }}>
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: feature.coordinates.lat, // Đúng thuộc tính lat
        lng: feature.coordinates.lng, // Đúng thuộc tính lng
      }}
      zoom={15}
      options={{
        mapTypeControl: false,
        disableDefaultUI: true,
        mapTypeId: "none",
      }}
    >
      {feature.coordinates.lat && feature.coordinates.lng && (
        <StreetViewPanorama
          key={`${feature.coordinates.lng},${feature.coordinates.lat}`} // Key thay đổi theo tọa độ
          position={{
            lat: feature.coordinates.lat,
            lng: feature.coordinates.lng,
          }}
          visible={true}
          options={{
            fullscreenControl: true,
            addressControl: false,
            zoomControl: false,
            showRoadLabels: false,
            motionTracking: false,
            motionTrackingControl: false,
            enableCloseButton: false,
          }}
          onLoad={(panorama) => {
            setPano(panorama);
          }}
        />
      )}
    </GoogleMap>
    <div
      className="old-price"
      style={{
        position: "relative",
        zIndex: 9999,
        height: "2rem",
        background: "#fff",
        width: "100%",
        marginTop: -32,
      }}
    ></div>
  </div>
</LoadScript>


)}

         {feature && (
       <div >


          
          <div className="info-grid">
            {/* Nhóm Tờ bản đồ và Thửa */}
            <div className="info-group">
              {feature.SHBANDO !== undefined && (
                <div className="info-item">
                  <span className="info-key">Tờ:&nbsp; </span>
                  <span className="info-value">{feature.SHBANDO},  <span className="info-key">Thửa:&nbsp; </span> {feature.SHTHUA}</span>
                </div>
              )}
            </div>

            {/* Các mục thông tin khác */}
              {feature.DIENTICH !== undefined && (
              <div className="info-item">
                <span className="info-key">Diện tích:  &nbsp;</span>
                <span className="info-value">
                  <span
                    className="info-value"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      fontSize: "clamp(13px, 2.5vw, 13px)",
                      fontWeight: "bold",
                      justifyContent: "center",
                      backgroundColor: landTypeColors[feature.KH2003] || defaultColor,
                    }}
                  >
                   &nbsp; &nbsp;  {feature.DIENTICH} m²  &nbsp; &nbsp;
                  </span>
                </span>
              </div>
            )}
            {feature.KH2003 !== undefined && (
              <div className="info-item">
                <span className="info-key">Loại đất:  &nbsp;</span>
                <span
                  className="info-value"
                  style={{
                    borderRadius: "5px",
                    color: "black",
                    fontSize: "clamp(13px, 2.5vw, 13px)",
                    fontWeight: "bold",
                    justifyContent: "center",
                    backgroundColor: landTypeColors[feature.KH2003] || defaultColor,
                  }}
                >
                  &nbsp; &nbsp; {feature.KH2003} &nbsp; &nbsp;
                </span>
              </div>
            )}
            {feature.TENCHU !== undefined && (
              <div className="info-item">
                <span className="info-key">Tên chủ:  &nbsp;</span>
                <span className="info-value">{feature.TENCHU}</span>
              </div>
            )}
            {feature.DIACHI !== undefined && (
              <div className="info-item">
                <span className="info-key">Địa chỉ:  &nbsp;</span>
                <span className="info-value">{feature.DIACHI}</span>
              </div>
            )}

          {locationName && (
              <div className="location-info">
                <p>{locationName}</p>
              </div>
            )}

                              
                  {feature.coordinates && (
                    <div className="info-item">
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ color: "#FF5722", marginRight: "5px" }}
                      ></i>
                      <span
                        className="info-value"
                        onClick={() => {
                          const lng = feature.coordinates.lng;
                          const lat = feature.coordinates.lat;
                          if (typeof lng === 'number' && typeof lat === 'number') {
                            navigator.clipboard.writeText(`${lng.toFixed(5)}, ${lat.toFixed(5)}`);
                            alert("Tọa độ đã được sao chép!");
                          } else {
                            alert("Tọa độ không hợp lệ!");
                          }
                        }}
                        style={{ cursor: "pointer", color: "#0022ff", textDecoration: "underline" }}
                      >
                        {typeof feature.coordinates.lng === 'number' && typeof feature.coordinates.lat === 'number'
                          ? `${feature.coordinates.lng.toFixed(5)}, ${feature.coordinates.lat.toFixed(5)}`
                          : 'Tọa độ không hợp lệ'}
                      </span>
                    </div>
                  )}

             <div className="land-type-details">
              Khái niệm các loại đất
                <div
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px",
                    padding: "10px",
                    marginBottom: "10px",
                    fontSize: "clamp(13px, 2.5vw, 13px)",
                    fontWeight: "bold",
                    whiteSpace: "pre-wrap",
                    color: "black",
                  }}
                >
                  {/* Hiển thị thông tin dựa vào giá trị KH2003 */}
                  {kh2003Name[feature.KH2003] || "Không tìm thấy thông tin."}
                </div>
              </div>

          </div>
          

          {/* Render children here */}
          {children && <div className="custom-children">{children}</div>}
        </div>
      )}
    </div>
  );
};

// Helper function to format keys (e.g., convert camelCase to Title Case)
const formatKey = (key) => {
  return key
    .replace(/([A-Z])/g, ' $1') // Insert space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
};

// Helper function to format values
const formatValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    // For objects, format key-value pairs
    return Object.entries(value)
      .map(([k, v]) => `${k}: ${v}`)
      .join(', ');
  }
  return value;
};

InfoPanel.propTypes = {
  feature: PropTypes.shape({
    SHBANDO: PropTypes.number,
    SHTHUA: PropTypes.number,
    DIENTICH: PropTypes.number,
    KH2003: PropTypes.string,
    TENCHU: PropTypes.string,
    DIACHI: PropTypes.string,
    coordinates: PropTypes.shape({
      lng: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
    }),
    // Add other fields as needed
  }),
  setSelectedFeature: PropTypes.func.isRequired,
  children: PropTypes.node, // Added children prop
};

// Optional: Default Props if needed
InfoPanel.defaultProps = {
  feature: null,
  children: null,
};

export default InfoPanel;
