import React, { useImperativeHandle, useState, forwardRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
} from '@mui/material';
 
const LayerSelector = forwardRef(({ layers, toggleLayer }, ref) => {
  const [open, setOpen] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(() => {
    if (layers?.raster1) return 'raster1';
    if (layers?.raster2) return 'raster2';
    if (layers?.raster3) return 'raster3';
    return '';
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    handleOpen,
  }));

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedLayer(value);
    toggleLayer(value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Chọn Lớp Bản Đồ</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset">
          <FormLabel component="legend">Các Lớp Bản Đồ Có Sẵn</FormLabel>
          <RadioGroup
            aria-label="layer"
            name="layer"
            value={selectedLayer}
            onChange={handleChange}
          >
            <FormControlLabel
              value="raster1"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center">
                 
                  Kế hoạch sử dụng đất
                </Box>
              }
            />
            <FormControlLabel
              value="raster2"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center">
                 
                  Quy hoạch sử dụng đất
                </Box>
              }
            />
            <FormControlLabel
              value="raster3"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center">
            
                  Quy hoạch xây dựng
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default LayerSelector;
