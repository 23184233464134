import L from "leaflet";

window.copyPrompt = function () {
  window.open("https://www.google.com/maps/place/"  + LS.Send.lat + ',' + LS.Send.lng +"/data=!3m1!1e3" );
  navigator.clipboard.writeText('AAAAAAAAA');
};
 
window.copyPrompt1 = function () {
  window.prompt(
    "Send this location with: Ctrl+C, Enter",
    "" +
      window.location.origin +
      window.location.pathname +
      "?" +
      "lat" +
      "=" +
      LS.Send.lat +
      "&" +
      "lng" +
      "=" +
      LS.Send.lng +
      "&" +
      "M" + 
      "=" +
      LS.Send.Message
  );
  navigator.clipboard.writeText('aaaaa');
};
 

window.copyPrompt2 = function () {
  window.open("https://www.google.com/maps/dir/?api=1&destination="+ LS.Send.lat + "," + LS.Send.lng +"&travelmode=driving" );
  navigator.clipboard.writeText('AAAAAAAAA');
};
 
// <input id="sendText" type="text" class="input-location-share" size="30" onkeyup="L.LocShare.Send.UpdateMessage( this )" placeholder="nhập thông diệp"/>
L.LocShare = {};
var LS = L.LocShare; 
LS.Send = {};
LS.Send.Marker = {};
LS.Send.Popup = L.popup().setContent(
  `<div class="message-share-container">
  <button  class="codepro-custom-btn codepro-btn-59" class="copy-prompt" style="border: 2px solid #ffff; border-radius: 10px; color: #ffff; background-color: #3d94f6; height: 35px; font-size: 12px; margin: 5px;" onclick="copyPrompt2()"> &nbsp; <i class="fa fa-car" aria-hidden="true"></i> &nbsp;Dẫn đường &nbsp; </button>
  <br/>
  <button class="codepro-custom-btn codepro-btn-59" class="copy-prompt" style="border: 2px solid #ffff; border-radius: 10px; color: #ffff; background-color: #3d94f6; height: 35px; font-size: 12px; margin: 5px;" onclick="copyPrompt()"> &nbsp;<i class="fa fa-location-arrow" aria-hidden="true"></i>&nbsp; Chia sẻ vị trí &nbsp; </button>


</div>
`
);
LS.Receive = {};
LS.Receive.Marker = {};
LS.Receive.Popup = L.popup();
 
var sendIcon = L.icon({
  iconUrl:"../assets/icons/placeholder1.png",
    
  iconSize: [38, 38], // size of the icon
  iconAnchor: [18, 25], // point of the icon which will correspond to marker's location ..................<input id="sendText" type="text" class="input-location-share" size="5" onkeyup="L.LocShare.Send.UpdateMessage( this )" placeholder=""/>
  popupAnchor: [0, -20], // point from which the popup should open relative to the iconAnchor
});

var receiveIcon = L.icon({
  iconUrl:
  "../assets/icons/placeholder1.png",

     
  iconSize: [38, 38], // size of the icon
  iconAnchor: [18, 25], // point of the icon which will correspond to marker's location ..................<input id="sendText" type="text" class="input-location-share" size="5" onkeyup="L.LocShare.Send.UpdateMessage( this )" placeholder=""/>
  popupAnchor: [0, -20], // point from which the popup should open relative to the iconAnchor
});

L.Map.addInitHook(function () {
  this.sharelocationControl = new L.Control.ShareLocation();
  this.addControl(this.sharelocationControl);
  this.whenReady(function () {
    populateMarker(this);
  });
}); 

L.Control.ShareLocation = L.Control.extend({
  // Add a flag variable for toggling
  _isActive: false,

  onAdd: function () {
    var container = L.DomUtil.create( "custom-control");

    this.link = L.DomUtil.create( "a","custom-control", container);
    this.link.title = this.options.title;
    var userIcon = L.DomUtil.create("img", "img-responsive", this.link);
    userIcon.title = "chia sẻ vị trí";
    userIcon.src = "../assets/icons/placeholder1.png";
    userIcon.style.background = "#ffffff";
    userIcon.style.borderRadius = "5px";
    userIcon.style.color = "#ffffff";
    userIcon.style.width = "32px";
    userIcon.style.height = "32px";
    userIcon.style.fontSize = "10px";
    userIcon.style.marginRight = "9px";
    userIcon.style.marginTop = "9px";
    userIcon.alt = "";
    userIcon.setAttribute("role", "presentation");
    this.link.href = "#";
    this.link.setAttribute("role", "button");
    L.DomEvent.on(this.link, "click", this._toggleClick, this); // Change to toggle function
    

    return container;
  },

  _toggleClick: function (e) {
    L.DomEvent.stopPropagation(e);
    L.DomEvent.preventDefault(e);
    
    if (this._isActive) {
      // Deactivate functionality or perform your deactivation logic
      this._isActive = false;
      // Additional logic to deactivate
    } else {
      // Activate functionality or perform your activation logic
      this._isActive = true;
      placeMarker(this._map); // Example: Call the function to place a marker
      // Additional logic to activate
    }
  },
});

var populateMarker = function (selectedMap) {
  // replace the line below with the results of any Url parser
  var intermediate = getJsonFromUrl();
  if (isFinite(intermediate.lat) && isFinite(intermediate.lng)) {
    LS.Receive.message = intermediate.M;
    LS.Receive.lat = +intermediate.lat;
    LS.Receive.lng = +intermediate.lng;
    var text =
      "<table><tr><td><p>" +
      LS.Receive.message +
      "</p></td><td><p>Lat: " +
      LS.Receive.lat +
      "</p><p>Lng: " +
      LS.Receive.lng +
      "</p></td></tr></table>";
    //    LS.Receive.Popup.setContent(LS.Receive.message)
    LS.Receive.Marker = L.marker([LS.Receive.lat, LS.Receive.lng], {
      icon: receiveIcon,
    });
    LS.Receive.Marker.bindPopup(LS.Receive.message);
    LS.Receive.Marker.addTo(selectedMap);
    LS.Receive.Marker.openPopup();
  }
};

function getJsonFromUrl() {
  var params = {};
  params.query = window.location.search.substr(1);
  params.parsed = decodeURIComponent(params.query);
  params.data = params.parsed.split("&");
  params.result = {};
  for (var i = 0; i < params.data.length; i++) {
    var item = params.data[i].split("=");
    params.result[item[0]] = item[1];
  }
  // This will return all of the data in the query parameters in object form
  // getJsonFromUrl() only splits on ampersand and equals -- jquery can do better
  // But so could you!! submit a pull request if you've got one!
  return params.result;
}

function placeMarker(selectedMap) {
  //  var test = LS.Send.Marker._latlng
  //  if ( isFinite(test.lat) && isFinite(test.lng) ){
  if (!LS.Send.Marker._latlng) {
    LS.Send.Marker = L.marker(selectedMap.getCenter(), {
      draggable: true,
      icon: sendIcon,
    });
    setSendValues(selectedMap.getCenter());
    LS.Send.Marker.on("dragend", function (event) {
      setSendValues(event.target.getLatLng());
      LS.Send.Marker.openPopup();
    });
    LS.Send.Marker.bindPopup(LS.Send.Popup);
    LS.Send.Marker.addTo(selectedMap);
  } else {
    LS.Send.Marker.setLatLng(selectedMap.getCenter());
  }
  //selectedMap.setView( location , 16 )
  LS.Send.Marker.openPopup();
  //  }
}

LS.Send.UpdateMessage = function (text) {
  var encodedForUrl = encodeURIComponent(text.value);
  LS.Send.Message = encodedForUrl;
};

function setSendValues(result) {
  LS.Send.lat = result.lat;
  LS.Send.lng = result.lng;
}
export default function ShareLocation() {
  

  
  return (
    <div
 
    >
 
    </div>
  );
}