import React, { useEffect, useState ,useRef} from "react";
import { MapContainer, TileLayer, useMapEvent,useMapEvents  ,useMap ,Tooltip ,Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import MapBoxVectorTile from "../../components/MapBoxVectorTile";
import classes from "./index.module.scss";
import { Utils } from "../../Utils/Utils";
import { Services } from '../../APIs/Services';
import L, { DivIcon } from 'leaflet';
import {  button ,Avatar ,Container , Box ,Moda ,Button} from '@mui/material';
import DrawTool from "../../components/DrawTool";
import { connect } from "react-redux";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Control from "react-leaflet-custom-control";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NewMapNotification from '../NewMapNotification';
import MarkerClusterGroup from "react-leaflet-cluster";
import ChangeView from '../ChangeMapView';
import DialogMarker from './../DiaglogMarker';
import SearchMapView2 from '../laban';
import SearchMapView1 from '../SearchMapView1';
import SearchMapView8 from '../SearchMapView8';
import SearchMapView9 from '../SearchMapView9';
import BottomNavComponent from '../../components/BottomNav';
const modalStyle3 = {
  width: ['100%', '70%'], // Set width based on breakpoints
  transform: 'translateX(-50%)', // Center horizontally on desktop
  position: 'absolute',
  border: '1px solid yellow',
  background: '#fff',
  padding: 4, // Use padding instead of shorthand 'p'
  boxShadow: 10,
  position: 'fixed',
  bottom: -100,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on desktop
  height: 0,
  borderTopLeftRadius: 10, // Apply border radius directly
  borderTopRightRadius: 10, // Apply border radius directly
  borderBottomLeftRadius: 0, // Apply border radius directly
  borderBottomRightRadius: 0, // Apply border radius directly
};

const tabBarStyle = {
  height:"3rem",
  position: 'fixed',
  bottom: 0,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on mobile
  transform: 'translateX(-50%)', // Center horizontally on mobile
};

// Override styles for desktop
tabBarStyle['@media screen and (min-width: 768px)'] = {
  width: '50%', // 50% width on desktop
  left: '50%', // Center horizontally on desktop
  transform: 'translateX(-50%)', // Center horizontally on desktop
};
 
 

 

function getBoundsMap(arrCoordinate) {
  const corner1 = L.latLng(arrCoordinate[1], arrCoordinate[0]);
  const corner2 = L.latLng(arrCoordinate[3], arrCoordinate[2]);
  const bounds = L.latLngBounds(corner1, corner2);

  return bounds;
}



const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<span></span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(0,0, true),
  })
}
 
 

const Map = (props ) => {

  const { dataMapJson, handleLongPress, handleCurrentViewChangen ,initialHeading = 0  } = props;
  const { bounds, center } = dataMapJson;
  const mapBound = getBoundsMap(bounds);
  const centerMap = [center[1], center[0]];
  const zoomMap = center[2];
 
  const [clickedCard, setClickedCard] = useState(false);
  const [currentView, setCurrentView] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isAddMarker, setIsAddMarker] = React.useState(false);
  const [markers, setMarkers] = useState([])
  const [currentMarker, setCurrentMarker] = useState(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)


  const [showKHSDDLayer, setShowKHSDDLayer] = useState(false);

  const handleClick = () => {
    setShowKHSDDLayer(!showKHSDDLayer);
  };


  
  

  // const [isOpen, setIsOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsOpen(true);
  // };

  const [isOpen2, setIsOpen2] = useState(false);
  const handleOpenModal2 = () => {
    setIsOpen2(true);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleOpenModal1 = () => {
    setIsOpen1(true);
  };

  // const [isOpen5, setIsOpen5] = useState(false);
  // const handleOpenModal5 = () => {
  //   setIsOpen5(true);
  // };
  const [isOpen8, setIsOpen8] = useState(false);
  const handleOpenModal8 = () => {
    setIsOpen8(true);
  };


  
  const modals = [
    // Đặt tên các modal tại đây, ví dụ:
    // 'modal1',
    // 'modal2',
    // 'modal3',
  ];

  // this.AAFiPnBgK2o = '1765258895';
  // this.XBEeQeFPIM0qIMQlRaMlNrCkRj = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw'; vip


  // this.AAFiPnBgK2o = '1765258895';
  // this.XBEeQeFPIM0qIMQlRaMlNrCkRj = '6875029358:AAFgQoqAiVcDL2NUhmuRI_1UgXT_ohlrj90'; ko vip


  // đây là ứng dụng , hoàn toàn miễn phí  mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.


  // đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.
  // const [isOpen9, setIsOpen9] = useState(false);

    const [isOpen9, setIsOpen9] = useState(true);
  const handleOpenModal9 = () => {
    setIsOpen9(true);
  };
 
 
  
  // Hàm chọn ngẫu nhiên một modal từ danh sách
  const getRandomModal = () => {
    const randomIndex = Math.floor(Math.random() * modals.length);
    return modals[randomIndex];
  };
  
  // Hàm mở modal và thiết lập lại đợi 60 giây cho lần mở modal tiếp theo
  const openModalAndScheduleNext = () => {
    const randomModal = getRandomModal(); // Chọn ngẫu nhiên một modal
    console.log(`Hiển thị modal: ${randomModal}`);
    setIsOpen9(true); // Mở modal
  
    // Thiết lập lại đợi 60 giây để mở modal tiếp theo
    setTimeout(() => {
      setIsOpen9(true); // Đóng modal hiện tại
      openModalAndScheduleNext(); // Gọi lại hàm này sau khi đã đợi 60 giây
    }, 1200000); // 60 giây là 60000 miligiây
  };
  useEffect(() => {
    openModalAndScheduleNext(); // Bắt đầu quá trình hiển thị modal ngẫu nhiên
  }, []); // Thực hiện chỉ một lần khi component được render
  
  
  // Gọi hàm mở modal lần đầu khi component được render
  useEffect(() => {
    openModalAndScheduleNext(); // Gọi hàm mở modal và thiết lập đợi 20 giây cho lần mở modal tiếp theo
  }, []); // Chạy chỉ một lần sau khi component được render

 
   
const handleCurrentViewChange = (currentView) => {
  Services.getCardMarkers(currentView).then((data) => {
  });
};

  useEffect(() => {
    Services.getMarkers().then((data) => {
      handleCurrentViewChange(data.data.data);
    });
  }, []);

  useEffect(()=>{ 
    Services.getMarkers().then(data => {
      setMarkers(data.data.data)
    })
  },[])

  // Disable your component when it's out of view
 
  // useEffect(() => {
  //   console.log("AAAA")
  // }, [isShowPlaceInfo])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const toggleSidebarClosePinAlert = () => {
    // console.log('Closing dialog');
    toggleSidebarClosePinAlert(false);
  };
  

  const toggleSidebar1 = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }
 
  const [opacity, setOpacity] = useState(0.);
  const [opacity1, setOpacity1] = useState(0);
  const [opacity4, setOpacity4] = useState(0);
  const [opacity3, setOpacity3] = useState(0);
  const [opacity2, setOpacity2] = useState(0);
  const [overallOpacity, setOverallOpacity] = useState(0.1);
 

  const [selectedLayer1, setSelectedLayer1] = useState(false);
  const [selectedLayer2, setSelectedLayer2] = useState(false);
  const [selectedLayer3, setSelectedLayer3] = useState(false);
  const [selectedLayer4, setSelectedLayer4] = useState(false);

 



const handleLayerChange1 = (layer) => {

switch (layer) {
  case "ThuaDat":
    setSelectedLayer1(!selectedLayer1);
    break;
  case "KHSDD2022":
    setSelectedLayer2(!selectedLayer2);
    break;
  case "KHSDD2030":
    setSelectedLayer3(!selectedLayer3);
    break;
  case "QHPKQHXD":
    setSelectedLayer4(!selectedLayer4);
    break;
  // Add more cases for additional layers if needed
  default:
    break;
}
};

 
  // Override styles for desktop
  tabBarStyle['@media screen and (min-width: 768px)'] = {
    width: '50%', // 50% width on desktop
    left: '50%', // Center horizontally on desktop
    transform: 'translateX(-50%)', // Center horizontally on desktop
  };
  
  


  
  const [openPinAlert, setOpenPinAlert] = useState(false);

  const handleOpenPinAlert = () => {
    setOpenPinAlert(true);
  };
  const handleClosePinAlert = () => {
    // console.log('Closing dialog');
    setOpenPinAlert(false);
  };
   

  const [openNewMap, setOpenNewMap] = useState(false);
  const [dataNotification, setDataNotification] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Services.getSettingNewsMap("");
        const data = response.data;
  
        if (data.data !== null) {
          const arr = data.data.filter((value) => value.is_active === true);
  
          if (arr.length > 0) {
            // Không mở modal ngay lúc này
            // setOpenNewMap(true);
            setDataNotification(arr);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render
  
  const handleOpenMapModal = () => {
    setOpenNewMap(true);
  };
  
  const handleCloseMapModal = () => {
    setOpenNewMap(false);
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };


 

  const toggleAddMarker = () => {
    setIsAddMarker((prev) => !prev); // Toggle the value
  };

  
    useEffect(() => {
      Services.getMarkers().then((data) => {
        setCurrentView(data.data.data);
        handleCurrentViewChange(data.data.data);
      });
    }, []);
  
    useEffect(()=>{ 
      Services.getMarkers().then(data => {
        setMarkers(data.data.data)
      })
    },[])


    
const [isMarkerVisible, setIsMarkerVisible] = useState(true);

const toggleMarkerVisibility = () => {
  setIsMarkerVisible(!isMarkerVisible);
};


const [open, setOpen] = useState(false);
const [vipDialogOpen, setVipDialogOpen] = useState(false);
const [isVip, setIsVip] = useState(false); // Giả định trạng thái VIP của người dùng

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose1 = () => {
  setOpen(false);
  setVipDialogOpen(false);
};

const handleSelectPlace = (placeData) => {
  // Thực hiện hành động với placeData nếu là VIP
  if (isVip) {
    console.log('Selected place:', placeData);
    handleClickOpen();
  } else {
    setVipDialogOpen(true);
  }
};

const upgradeToVip = () => {
  // Logic để nâng cấp người dùng lên VIP
  setIsVip(true);
  setVipDialogOpen(false);
  window.open("/THANH-toan1");
};
const handleClose = () => {
  setOpenModel(false)
}
  
const [openModel, setOpenModel] = useState(false);
const handleOpenVipDialog = () => {
  setVipDialogOpen(true);
};

const handleCloseVipDialog = () => {
  setVipDialogOpen(false);
};


  return (

 
    <MapContainer
    style={{
      zIndex: "0",
      height: '100%',
      position: 'absolute',
   
    }}
 

      scrollWheelZoom={true}
      className={classes.mapContainer}
      attributionControl={false}
      zoom={zoomMap}
      center={centerMap}
      bounds={mapBound}
      zoomControl={false}
      maxNativeZoom={19.4}
      minZoom={5} 
      maxZoom={20}
      wheelPxPerZoomLevel={99999999999999999}
      tileSize={64}
      loading="lazy"
      
      
    >
    
    <TileLayer
      url="https://mt1.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
      attribution= '&copy; <a href="https://tcmap.vn/">chau:0799.799.993</a>'
      tileSize= {256}
      maxNativeZoom={26}
      loading="lazy"
      minZoom={0}
      maxZoom={26}
      
    />
 
 
 

 <MarkerClusterGroup
   onClick={(e) => console.log('onClick', e)}
   iconCreateFunction={createClusterCustomIcon}
   maxClusterRadius={mapBound >= 50 ? 800 : 99999999}
   spiderfyOnMaxZoom={false}
   showCoverageOnHover={true}
   maxNativeZoom={16}
   maxZoom={17}
   minZoom={15}
   disableClusteringAtZoom={19}
   zoomToBoundsOnClick={false}
   animate={false}
   scrollWheelZoom={true}
   keepInView={true}
        >
     {isMarkerVisible &&   markers.map((data, index) => (
      <Marker
        key={index}
        keepInView={true}
        position={[data.lag, data.lng]}
        style={{
          zIndex: 999999,
        }}
        eventHandlers={{
          click: (e) => {
            setCurrentMarker(data);
          },
          currentView: [data.lag, data.lng],
          clickedCard: true,
          isMarkerDialogOpen: true,
          currentMarker: data
        }}
        permanent={true}
        icon={L.divIcon({
          className: "leaflet-tooltip4",
          iconUrl: "./assets/icons/pint.png",
          html: `
            <div style="display: flex; flex-direction: column; align-items: center;" >
              <div style="
                width: auto;
                border: 2px solid #3d94f6;
                border-radius: 10px;
                color: #3d94f6;
                background-color: white;
                height: 35px;
                font-size: 13px;
                margin: 5px;
                margin-top: 3px;
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
                <p style="margin: 0;">&nbsp; ${data.name.slice(0, 7)}${data.name.length > 10 ? '...' : ''} &nbsp;</p>
              </div>
            </div>
          `,
          iconSize: [100, 0],
        })}
      />
    ))}

{!isMarkerVisible ? (
  <MapBoxVectorTile
    className={classes.mapContainer}
    isAddMarker={isAddMarker}
    setIsAddMarker={setIsAddMarker}
    opacity={props.opacity}
    keepInView={true}
  />
) : null}



        </MarkerClusterGroup> 

 
 
<div
  className="leaflet-control"
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", 
    position: 'absolute',
    right: 0,
    top:387,
    marginTop: "1px",
    background:"#fff",
    borderRadius:"5px"
    ,zIndex:999999 
  }}
> 
 
<div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", 
    position: 'absolute',
    right: 0,
    top:387,
    marginTop: "1px",
    background:"#fff",
    borderRadius:"5px"
    ,zIndex:999999 ,
   
  }}

        > 
          


           <BottomNavComponent
        handleClosePinAlert={handleClosePinAlert}
        handleOpenModal1={handleOpenModal1}
        handleOpenModal8={handleOpenModal8}
        handleOpenPinAlert={handleOpenPinAlert}
        handleOpenModal2={handleOpenModal2}
        handleOpenMapModal={handleOpenMapModal}
      />
 

</div>


           



           
</div>
             
 



 
    
    </MapContainer>
    
  );
};

const mapStateToProps = (state) => ({
  dataMapJson: state.mapBoxVectorTile.dataMapJson,
  data: state.data
});


const mapDispatchToProps = {};

 
export default connect(mapStateToProps, mapDispatchToProps)(Map);
