
import React, { useEffect, useRef } from 'react';
 
import ReactDOM from 'react-dom';
import './index.css';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
// import HomePage from './components/HomePage';
import reportWebVitals from './reportWebVitals';
import store from "./state/store";
import { Provider } from "react-redux";
import "./styles.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate ,
} from "react-router-dom";
import Dangky1 from "./components/dangky/dangky";
import App from "./layouts";
 
import HomePage from "./components/HomePage";
import NewsHome from "./components/quy-hoach/Pages/NewsHome";
import NewsPage from "./components/quy-hoach/Pages/NewsPage";
// import disableDevtool from 'disable-devtool';
// disableDevtool();

// document.addEventListener('gesturestart', function (e) {
//   e.preventDefault();
// });

// window.addEventListener("popstate", function (e) {
//   if (window.location.pathname === "/") {
//     e.preventDefault();
//     setTimeout(function () {
//       window.history.forward();
//     }, 500);
//   }
// });

// var devToolStatus;
// var element = new Image();
// Object.defineProperty(element, 'id', {
//   get: function () {
//     devToolStatus = true;
//     throw new Error("Dev tools checker");
//   }
// });


// document.addEventListener("DOMContentLoaded", function () {
//   var scripts = document.getElementsByTagName('script');

//   for (var i = 0; i < scripts.length; i++) {
//     scripts[i].remove();
//   }
// });

  

function AppWithDynamicTitle({ data }) {
  const elementRef = useRef(null);
 
function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  
  // Kiểm tra xem có token không sau 1 giây
  React.useEffect(() => { 
    if (token === null) {
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    }
  }, [token]); 

  // Kiểm tra lỗi trang
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (checkPageError()) {
        clearInterval(interval);
        setTimeout(() => {
          window.location.href = '/';  
        }, 1000);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const checkPageError = () => {
    return false;
  };

  return token !== null ? children : <h1>Loading...</h1>; 
}


  // const interval2 = setInterval(() => {
  //   console.clear();
  //   performance.clearResourceTimings();
  // }, 500);
  
  // const interval = setInterval(() => {
  //   console.clear();
  //   setTimeout(() => {
  //     performance.clearResourceTimings();
  //   }, 100);
  // }, 1000);


  

  return (
    <div ref={elementRef}>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<HomePage />} />
<Route path="/login" element={<Login />} />
<Route path="/register" element={<Register />} />
<Route path="/forgotpassword" element={<ForgotPassword />} />
<Route path="/thanh-toan1" element={<Dangky1 />} exact /> 
<Route path="/quy-hoach" element={<NewsHome />} exact />
 <Route path="/quy-hoach/:id" element={<NewsPage />} />

    <Route
            path="/tintuc"
            element={<Navigate to="/quy-hoach" />}
          />
                    <Route
            path="/tintuc"
            element={<Navigate to="/quy-hoach" />}
          />
          <Route path="*" element={<Navigate to="/" />} />

   
  

          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/home" element={<HomePage />} />
          <Route
            path="/map"
            element={
              <PrivateRoute>
                <Provider store={store}>
                  <App />
                </Provider>
              </PrivateRoute>
            }
          />
            
            <Route
            path="/map:id"
            element={
              <PrivateRoute>
                <Provider store={store}>
                  <App />
                </Provider>
              </PrivateRoute>
            }
          />


<Route
            path="/App"
            element={
              <PrivateRoute>
                <Provider store={store}>
                  <App />
                </Provider>
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    
    </div>
  );
}



const data = {
  title: "tra quy hoạch toàn hoàn miễn phí",
};

ReactDOM.render(
  <React.StrictMode>
    <AppWithDynamicTitle data={data} />
 
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
 