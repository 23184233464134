// src/utils/mapDraw.js

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const initializeMapDraw = (map, setNotification, setIsMeasuring) => {
    const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            line_string: true,
            polygon: true,
            trash: true,
        },
        defaultMode: 'simple_select',
    });

    map.addControl(draw);

    const edgeLabelsLayerId = 'edge-labels-layer';
    const areaLabelLayerId = 'area-label-layer';

    const removeLayerAndSource = (layerId, sourceId) => {
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
        }
        if (map.getSource(sourceId)) {
            map.removeSource(sourceId);
        }
    };

    const updateAreaAndLengths = () => {
        const data = draw.getAll();

        // Remove all existing permanent layers related to drawing
        data.features.forEach(feature => {
            const uniqueLineLayerId = `${feature.id}-line-layer`;
            const uniqueEdgeLabelId = `${edgeLabelsLayerId}-${feature.id}`;
            const uniquePointLabelId = `${feature.id}-points-layer`;
            const uniqueAreaLabelId = `${areaLabelLayerId}-${feature.id}`; // New: Unique ID for area label

            // Remove line layer
            removeLayerAndSource(uniqueLineLayerId, uniqueLineLayerId);

            // Remove edge label layer
            removeLayerAndSource(uniqueEdgeLabelId, uniqueEdgeLabelId);

            // Remove point label layer
            removeLayerAndSource(uniquePointLabelId, uniquePointLabelId);

            // Remove area label layer
            removeLayerAndSource(uniqueAreaLabelId, uniqueAreaLabelId); // New: Remove area label
        });

        // Add new permanent layers if there are any features
        if (data.features.length > 0) {
            data.features.forEach(feature => {
                const uniqueLineLayerId = `${feature.id}-line-layer`;
                const uniqueEdgeLabelId = `${edgeLabelsLayerId}-${feature.id}`;
                const uniquePointLabelId = `${feature.id}-points-layer`;
                const uniqueAreaLabelId = `${areaLabelLayerId}-${feature.id}`; // New: Unique ID for area label

                // Add line layer with dashed lines
                map.addSource(uniqueLineLayerId, {
                    type: 'geojson',
                    data: feature,
                });

                map.addLayer({
                    id: uniqueLineLayerId,
                    type: 'line',
                    source: uniqueLineLayerId,
                    layout: {},
                    paint: {
                        'line-color': '#FF0000',
                        'line-width': 2,
                        'line-dasharray': [4, 4],
                    },
                });

                // Add point labels (numbers)
                const coordinates = feature.geometry.coordinates[0];
                const pointLabels = coordinates.map((coord, index) => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: coord,
                    },
                    properties: {
                        label: `${index + 1}`,
                    },
                }));

                map.addSource(uniquePointLabelId, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: pointLabels,
                    },
                });

                map.addLayer({
                    id: uniquePointLabelId,
                    type: 'symbol',
                    source: uniquePointLabelId,
                    layout: {
                        'text-field': ['get', 'label'],
                        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                        'text-size': 12,
                        'text-anchor': 'top',
                        'text-offset': [0, 0.5],
                    },
                    paint: {
                        'text-color': '#0000FF',
                        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                        'text-halo-width': 2,
                    },
                });

                // Add edge length labels
                const edgeLabels = coordinates.slice(0, -1).map((coord, index) => {
                    const start = coord;
                    const end = coordinates[index + 1];
                    const line = turf.lineString([start, end]);
                    const length = turf.length(line, { units: 'meters' });
                    const midpoint = turf.midpoint(turf.point(start), turf.point(end));

                    return {
                        type: 'Feature',
                        geometry: midpoint.geometry,
                        properties: {
                            length: `${length.toFixed(2)} m`,
                        },
                    };
                });

                map.addSource(uniqueEdgeLabelId, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: edgeLabels,
                    },
                });

                map.addLayer({
                    id: uniqueEdgeLabelId,
                    type: 'symbol',
                    source: uniqueEdgeLabelId,
                    layout: {
                        'text-field': ['get', 'length'],
                        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                        'text-size': 12,
                        'text-anchor': 'center',
                    },
                    paint: {
                        'text-color': '#000',
                        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                        'text-halo-width': 2,
                    },
                });

                // **New: Add Area Label**
                if (feature.geometry.type === 'Polygon') {
                    // Calculate area in square meters
                    const area = turf.area(feature);

                    // Format area (e.g., to two decimal places)
                    const formattedArea = `${area.toFixed(2)} m²`;

                    // Calculate centroid of the polygon
                    const centroid = turf.centroid(feature);

                    // Create a feature for the area label
                    const areaLabelFeature = {
                        type: 'Feature',
                        geometry: centroid.geometry,
                        properties: {
                            area: formattedArea,
                        },
                    };

                    // Add source for area label
                    map.addSource(uniqueAreaLabelId, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [areaLabelFeature],
                        },
                    });

                    // Add layer for area label
                    map.addLayer({
                        id: uniqueAreaLabelId,
                        type: 'symbol',
                        source: uniqueAreaLabelId,
                        layout: {
                            'text-field': ['get', 'area'],
                            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                            'text-size': 14,
                            'text-anchor': 'center',
                            'text-offset': [0, 0],
                        },
                        paint: {
                            'text-color': '#FF00FF',
                            'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                            'text-halo-width': 2,
                        },
                    });
                }
            });
        } else {
            setNotification({ message: 'Please use the drawing tool to draw a polygon!', type: 'info' });
        }
    };

    const updateEdgeLengthsDuringDrawing = (e) => {
        const mode = draw.getMode();
        
        if (mode === 'draw_polygon') {
            // Logic for drawing polygons
            const currentFeature = draw.getAll().features[0];
            if (!currentFeature) return;

            const coordinates = currentFeature.geometry.coordinates[0];
            const numPoints = coordinates.length;

            if (numPoints < 2) return;

            const lastPoint = coordinates[numPoints - 2];
            const currentMouseLngLat = e.lngLat;

            // Calculate length from last point to current mouse position
            const line = turf.lineString([lastPoint, [currentMouseLngLat.lng, currentMouseLngLat.lat]]);
            const length = turf.length(line, { units: 'meters' });

            // Calculate midpoint
            const midpoint = turf.midpoint(turf.point(lastPoint), turf.point([currentMouseLngLat.lng, currentMouseLngLat.lat]));

            // Create temporary edge label feature
            const tempEdgeLabel = {
                type: 'Feature',
                geometry: midpoint.geometry,
                properties: {
                    length: `${length.toFixed(2)} m`,
                },
            };

            // Add or update temporary line layer
            const uniqueTempLineId = 'temp-line-layer';
            if (map.getSource(uniqueTempLineId)) {
                map.getSource(uniqueTempLineId).setData(line);
            } else {
                map.addSource(uniqueTempLineId, {
                    type: 'geojson',
                    data: line,
                });

                map.addLayer({
                    id: uniqueTempLineId,
                    type: 'line',
                    source: uniqueTempLineId,
                    layout: {
                        'line-width': 3,
                        'line-color': '#00FF00',
                        'line-dasharray': [2, 2],
                    },
                    paint: {
                        'line-opacity': 0.7,
                    },
                });
            }

            // Add or update temporary edge label layer
            if (map.getSource('temp-edge-label')) {
                map.getSource('temp-edge-label').setData({
                    type: 'FeatureCollection',
                    features: [tempEdgeLabel],
                });
            } else {
                map.addSource('temp-edge-label', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [tempEdgeLabel],
                    },
                });

                map.addLayer({
                    id: 'temp-edge-label-layer',
                    type: 'symbol',
                    source: 'temp-edge-label',
                    layout: {
                        'text-field': ['get', 'length'],
                        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                        'text-size': 12,
                        'text-anchor': 'center',
                    },
                    paint: {
                        'text-color': '#ff0000',
                        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                        'text-halo-width': 2,
                    },
                });
            }

        } else if (mode === 'draw_line_string') {
            // Logic for drawing polylines
            const currentFeature = draw.getAll().features[0];
            if (!currentFeature) return;

            const coordinates = currentFeature.geometry.coordinates;
            const numPoints = coordinates.length;

            if (numPoints < 2) return;

            const startPoint = coordinates[numPoints - 2];
            const currentMouseLngLat = e.lngLat;

            // Calculate length from start point to current mouse position
            const line = turf.lineString([startPoint, [currentMouseLngLat.lng, currentMouseLngLat.lat]]);
            const length = turf.length(line, { units: 'meters' });

            // Calculate midpoint
            const midpoint = turf.midpoint(turf.point(startPoint), turf.point([currentMouseLngLat.lng, currentMouseLngLat.lat]));

            // Create temporary edge label feature
            const tempEdgeLabel = {
                type: 'Feature',
                geometry: midpoint.geometry,
                properties: {
                    length: `${length.toFixed(2)} m`,
                },
            };

            // Add or update temporary line layer
            const uniqueTempLineId = 'temp-line-layer';
            if (map.getSource(uniqueTempLineId)) {
                map.getSource(uniqueTempLineId).setData(line);
            } else {
                map.addSource(uniqueTempLineId, {
                    type: 'geojson',
                    data: line,
                });

                map.addLayer({
                    id: uniqueTempLineId,
                    type: 'line',
                    source: uniqueTempLineId,
                    layout: {
                        'line-width': 3,
                        'line-color': '#0000FF',
                        'line-dasharray': [2, 2],
                    },
                    paint: {
                        'line-opacity': 0.7,
                    },
                });
            }

            // Add or update temporary edge label layer
            if (map.getSource('temp-edge-label')) {
                map.getSource('temp-edge-label').setData({
                    type: 'FeatureCollection',
                    features: [tempEdgeLabel],
                });
            } else {
                map.addSource('temp-edge-label', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [tempEdgeLabel],
                    },
                });

                map.addLayer({
                    id: 'temp-edge-label-layer',
                    type: 'symbol',
                    source: 'temp-edge-label',
                    layout: {
                        'text-field': ['get', 'length'],
                        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                        'text-size': 12,
                        'text-anchor': 'center',
                    },
                    paint: {
                        'text-color': '#ff0000',
                        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                        'text-halo-width': 2,
                    },
                });
            }
        }
    };

    map.on('draw.create', updateAreaAndLengths);
    map.on('draw.delete', updateAreaAndLengths);
    map.on('draw.update', updateAreaAndLengths);
    map.on('draw.selectionchange', updateAreaAndLengths);

map.on('draw.modechange', (e) => {
    const isDrawingMode = e.mode === 'draw_polygon' || e.mode === 'draw_line_string';
    setIsMeasuring(isDrawingMode); // Cập nhật trạng thái bên ngoài

    if (isDrawingMode) {
        map.on('mousemove', updateEdgeLengthsDuringDrawing); // Bật cập nhật tạm thời
        map.getCanvas().style.cursor = 'crosshair'; // Thay đổi con trỏ
    } else {
        map.off('mousemove', updateEdgeLengthsDuringDrawing); // Tắt cập nhật tạm thời
        removeLayerAndSource('temp-edge-label-layer', 'temp-edge-label'); // Xóa lớp tạm
        removeLayerAndSource('temp-line-layer', 'temp-line-layer');
        map.getCanvas().style.cursor = ''; // Reset con trỏ
    }
});


    const reorderLayers = () => {
        const style = map.getStyle();
        const layers = style.layers;

        // Iterate through layers to find all gl-draw layers
        layers.forEach(layer => {
            if (layer.id.startsWith('gl-draw')) {
                try {
                    // Move 'vector-layer' above the current gl-draw layer
                    map.moveLayer('vector-layer', layer.id);
                } catch (error) {
                    console.error(`Error moving 'vector-layer' above '${layer.id}':`, error);
                }
            }
        });
    };

    // Return a cleanup function to remove all event listeners and layers added by this utility
    const cleanup = () => {
        map.off('draw.create', updateAreaAndLengths);
        map.off('draw.delete', updateAreaAndLengths);
        map.off('draw.update', updateAreaAndLengths);
        map.off('draw.selectionchange', updateAreaAndLengths);
        map.off('draw.modechange', () => {});
        map.off('mousemove', updateEdgeLengthsDuringDrawing);

        // Remove all permanent layers related to drawing
        const data = draw.getAll();
        data.features.forEach(feature => {
            const uniqueLineLayerId = `${feature.id}-line-layer`;
            const uniqueEdgeLabelId = `${edgeLabelsLayerId}-${feature.id}`;
            const uniquePointLabelId = `${feature.id}-points-layer`;
            const uniqueAreaLabelId = `${areaLabelLayerId}-${feature.id}`; // New: Unique ID for area label

            removeLayerAndSource(uniqueLineLayerId, uniqueLineLayerId);
            removeLayerAndSource(uniqueEdgeLabelId, uniqueEdgeLabelId);
            removeLayerAndSource(uniquePointLabelId, uniquePointLabelId);
            removeLayerAndSource(uniqueAreaLabelId, uniqueAreaLabelId); // New: Remove area label
        });

        // Remove temporary layers if they exist
        removeLayerAndSource('temp-edge-label-layer', 'temp-edge-label');
        removeLayerAndSource('temp-line-layer', 'temp-line-layer');
        removeLayerAndSource('temp-points-layer', 'temp-points-layer');

        // Remove MapboxDraw control
        map.removeControl(draw);
    };

        if (map.isStyleLoaded()) {
        reorderLayers();
    } else {
        map.on('style.load', reorderLayers);
    }

    return { draw, cleanup };
};

export default initializeMapDraw;
