import React from 'react';
 
 
import { Services } from '../APIs/Services';
import { Utils } from '../Utils/Utils';
import { Navigate } from 'react-router';
import Spinner from 'react-bootstrap/Spinner'
import { MapContainer, Marker,useMapEvents,LayersControl,Popup,
  MapConsumer , TileLayer, Tooltip as LeafletTooltip } from "react-leaflet";
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import Control from "react-leaflet-custom-control";
import classes from "./MapBoxVectorTile/index.module.scss";
 
import "leaflet/dist/leaflet.css";
import MyComponent from './MapEvent';
 
import InfoDialog from './InfoDialog'
// import MarkerForm from './MarkerForm'
import NewMapNotification from './NewMapNotification';
import ChangeView from './ChangeMapView';
import ContentAdminView from './ContentAdminView';
import DialogMarker from './DiaglogMarker';
import LocateControl from './LocateControl';
import SearchMapView1 from './SearchMapView1';
import SearchMapView2 from './laban';
import SearchMapView8 from './SearchMapView8';
import ShareLocation from './ShareLocation';
import SearchMapView from './SearchMapView';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {DISTRICTS} from './../constants/maps.constant'
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl } from 'react-bootstrap';
import Geoman from "./Geoman";
 
import axios from 'axios';
 
 
import { CardMedia,Card,AppBar, Box, Badge, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, TextField, Modal, Stack, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, ButtonBase, Divider,Link } from '@mui/material';
 
 
 
 
 


import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import disableDevtool from 'disable-devtool';

// disableDevtool();
 



 

 
const nangcap = () => {
  window.open("/THANH-toan1");
  return null;
};


 
  
 

const modalStyle3 = {
  width: ['100%', '40%'], // Set width based on breakpoints
  transform: 'translateX(-50%)', // Center horizontally on desktop
  position: 'absolute',
  border: 'none',
  background: '#fff',
  p: 4,
  boxShadow: 10,
  borderTopLeftRadius: 10, // Apply border radius directly
  borderTopRightRadius: 10, // Apply border radius directly
  borderBottomLeftRadius: 0, // Apply border radius directly
  borderBottomRightRadius: 0, // Apply border radius directly
  border: "1px solid Yellow",
  position: "fixed",
  bottom: -100,
  zIndex: 1000,
  transform: "translateX(-50%)", // Center horizontally on mobile
  left: "50%", // Center horizontally on desktop
  height:0,
};



const tabBarStyle = {
  height:"3rem",
  position: 'fixed',
  bottom: 0,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on mobile
  transform: 'translateX(-50%)', // Center horizontally on mobile
};

// Override styles for desktop
tabBarStyle['@media screen and (min-width: 768px)'] = {
  width: '50%', // 50% width on desktop
  left: '50%', // Center horizontally on desktop
  transform: 'translateX(-50%)', // Center horizontally on desktop
};
 
 



const commonStyle = {
  // Các thuộc tính chung khác của thanh
  cursor: "pointer",
  fontSize: "14px",
  display: "flex",
  top: 20,
  background: "none",
  left: "49%",
  border: "none",
  position: "absolute",
  transform: "translate(-50%, -50%)", // Đặt thanh ở trung tâm cả chiều dọc và ngang
};

 
 
 
 
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 
const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<span></span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(0,0, true),
  })
}
 
 


class HomePage extends React.Component {

  state = {
    "anchorElNav": null,
    "anchorElUser": null,
    "anchorNotification": null,
    "pages": [],
    "isSuperUser": false,
    "isInfoOpen": false,
    "isChangePassword": false,

    "errorChangeMessage": "",
    "oldPassword": "",
    "newPassword": "",
    "ReNewPassword": "",

    "userData": null,

    "isReadOnly": true,

    "openDialog": false,

    navigateToMap: false,
    tabIndex: 0,

    isAddMarker: false,
    isMarkerFormOpen: false,
    newMarkerCoords: null,
    markers: [],
    isDraging: false,
    cardMarkers:[],

    markerName: "",
    markerDes: "",
    markerImageURL: "",
    markerYoutubeURL: "",
    markerDistrict: "Long an",

    currentView: [16.027906, 108.143409],
    clickedCard: false,

    isMarkerDialogOpen: false,
    currentMarker: null,

    openSearchMap: false,
    listMapSearch: [],
    toSearchMapText: "",
    openConfirmAlert: false,
    openNewMap: false,
    dataNotification: "",
    settings: [],
    isAuth: false,
    notifications: [],
    badgeContent: 0,
    markerParam: null,
    opacity: 100,
    zoomLevel: 18, 
     tempOpacity: 100,
  }
 

 

  handleOpacityChange = (event) => {
    this.setState({
      tempOpacity: event.target.value,
    });
  };

  applyOpacity = () => {
    this.setState({
      opacity: this.state.tempOpacity / 100,
    });
  };

  // handleMarkerNameChange = (event) => {
  //   this.setState({
  //     markerName: event.target.value
  //   })
  // }

  // handleDistrictChange = (event) => {
  //   this.setState({
  //     markerDistrict: event.target.value
  //   })
  // }

  // handleMarkerDesChange = (event) => {
  //   this.setState({
  //     markerDes: event.target.value
  //   })
  // }

  // handleMarkerYoutubeChange = (event) => {
  //   this.setState({
  //     markerYoutubeURL: event.target.value
  //   })
  // }

  handleMarkerImageChange = (images) => {
    this.setState({
      markerImageURL: images.join(',')
    })
  }

  componentDidMount = () => {
      //   this.map = 17,
  //   this.map.on('zoomend', this.handleZoomChange);
    this.setState({
      isDraging: false
    })
    const query = new URLSearchParams(window.location.search);
      const markerId = query.get('marker')
      if (markerId != null) {
        Services.getMarker(markerId).then((data)=>{
          this.setState({
            currentView: [data.data.data.lag, data.data.data.lng],
            clickedCard: true,
            isMarkerDialogOpen: true,
            currentMarker: data.data.data
          })
        })
      }
    Services.getMarkers().then(data => {
      this.setState({
        markers: data.data.data,
      })
    })

    if (localStorage.getItem("token") != null){
      Services.user().then(data => {
        this.setState({
          userData: data.data.data
        })
      })
    }

    Services.getSettingNewsMap("").then(data=>{
      if (data.data.data != null) {
        let arr = data.data.data.filter((value) =>{
          return value.is_active == true
        })
        if (arr.length > 0) {
          this.setState({
            openNewMap: false,
            dataNotification: arr
          })
        }
      }
    })

    Services.getCardMarkers(this.state.currentView).then(data => {
      this.setState({
        cardMarkers: data.data.data
      })
    })

    // setInterval(()=>{
    //   Services.checkAuthentication(false).then(data=>{
    //     this.setState({
    //       notifications: data.data.data,
    //       badgeContent: data.data.data.filter((value) => {
    //         return !value.is_read
    //       }).length
    //     })
    //   })
    //   if (localStorage.getItem("token") == null){
    //     this.setState({
    //       isAuth: false,
    //       settings: []
    //     })
    //   } else {
    //     this.setState({
    //       isAuth: true,
    //       settings: []
    //     })
    //   }
    // }, 1000);

    
    
   
  }

  handleSetIsDraging = (isDraging) => {
    this.setState({
      isDraging: isDraging
    })
  }

  handleOpenNavMenu = (event) => {
    this.setState({
      "anchorElNav": event.currentTarget
    })
  };
  handleOpenUserMenu = (event) => {
    this.setState({
      "anchorElUser": event.currentTarget
    })
  };

  handleOpenNotifications = (event) => {
    this.setState({
      "anchorNotification": event.currentTarget
    })
    
  };

  handleCloseNavMenu = (indexPage) => {
    this.setState({
      tabIndex: indexPage
    })
    this.setState({
      "anchorElNav": null
    })
  };

  handleCloseUserMenu = () => {
    this.setState({
      "anchorElUser": null
    })
  };

  handleCloseNotifications = () => {
    this.setState({
      "anchorNotification": null
    })
  };

  handleClose = () => {
    this.setState({
      "errorChangeMessage": ""
    })
    this.setState({
      "isInfoOpen": false
    })
  }
  handleOpen = () => {
    this.setState({
      "isInfoOpen": true
    })
  }

  handleClickMap = (map) => {
    localStorage.setItem("mapID", map.id)
    this.setOpenSearchMap(false)
    window.location.href = "/map"
  }

  handleSelectMenu = (index) => {
    this.handleCloseUserMenu()
    if (this.state.isAuth) {
      switch (index) {
        case 0:
          this.setState({
            "isChangePassword": false
          })
          this.handleOpen()
          break
        case 1:
          this.setState({
            "isChangePassword": true
          })
          this.handleOpen()
        
        default:
          
        
      }
    } else {
      switch (index) {
        case 0:
          window.location.href = "/login";
          break;
        case 1:
          localStorage.removeItem("mapID")
          this.setState({
            navigateToMap: true
          })
          break;
        case 2:
            window.location.href = "/tintuc";
            break;
            break;
            case 3:
              window.open("https://tcmap.vn/dangky/index.html"); 
              break
              case 4:
              window.open("https://tcmap.vn/taiapp/index1.html"); 
              break
              case 5:
                window.open("https://zalo.me/0799799993");
              break
              case 6:
                window.location.href="tel:0799799993" ; 
              break
          
        default:
        
      }
    }
  }

  handleSelectNotification = (notification) => {
    this.handleCloseNotifications()
    this.setState({
      currentView: [notification.marker.lag, notification.marker.lng],
      clickedCard: true,
      isMarkerDialogOpen: true,
      currentMarker: notification.marker
    })
    Services.updateNotification(notification.id).then(data=>{
    })
  }

  openMarkerForm = (newMarkerCoords) => {
    this.setState({
      isMarkerFormOpen: true,
      isAddMarker: false,
      newMarkerCoords: newMarkerCoords
    })
    console.log(newMarkerCoords)
  }

  handleClickConfirmAlert = () => {
    this.setState({
      openConfirmAlert: true
    })
  };

  handleCloseConfirmAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openConfirmAlert: false
    })
  };

  handleCloseMarker = () =>{ 
    this.setState({
      isMarkerFormOpen: false
    })
  }

  handleCloseNewMap = () =>{ 
    this.setState({
      openNewMap: false
    })
  }

  handleCurrentViewChange = (currentView) => {
    this.setState({
      currentView: currentView
    })
    Services.getCardMarkers(currentView).then(data => {
      this.setState({
        cardMarkers: data.data.data
      })
    })
  }

  setUnClicked = () =>{
    this.setState({
      clickedCard: false
    })
  }

  handleMarkerDialogClose = () => {
    this.setState({
      isMarkerDialogOpen: false
    })
  }

  setOpenSearchMap = (isOpen) =>{
    this.setState({
      openSearchMap: isOpen
    })
  }

  setOpenSearchMap1 = (isOpen) =>{
    this.setState({
      openSearchMap1: isOpen
    })
  }
  setOpenSearchMap2 = (isOpen) =>{
    this.setState({
      openSearchMap2: isOpen
    })
  }

  setOpenSearchMap8 = (isOpen) =>{
    this.setState({
      openSearchMap8: isOpen
    })
  }

  setOpenSearchMap10 = (isOpen) =>{
    this.setState({
      openSearchMap10: isOpen
    })
  }
 
 

  NewMapNotification = (isOpen) =>{
    this.setState({
      openNewMap: isOpen
    })
  }


  setTextSearchMap = (event) => {
    this.setState({
      toSearchMapText: event.target.value
    })
    if (event.target.value.trim().length > 0) {
      Services.getSearchMaps(event.target.value).then(data=>{
        this.setState({
          listMapSearch: data.data.data
        })
      })
    } else {
      this.setState({
        listMapSearch: []
      })
    }
  }


  // componentDidMount() {
  //   this.map = 17,
  //   this.map.on('zoomend', this.handleZoomChange);
  // }

  // componentWillUnmount() {
  //   this.map.off('zoomend', this.handleZoomChange);
  // }

  handleZoomChange = () => {
    const currentZoomLevel = this.map.getZoom();
    this.setState({ zoomLevel: currentZoomLevel });
  };


  handleOpenInfoDialog = () => {
    this.setState({ isInfoOpen: true });
  }

 
  
 
    render() {

    return (
      <div>
        {this.state.navigateToMap ? <Navigate to="/map"></Navigate>: null}
       
          <div style={{ zIndex: "0", height: '100%', position: 'absolute', left: 0, right: 0,  background : "white"}}>

            
            


 
<MapContainer 
    style={{ zIndex: "0", height: '100%', position: 'absolute', left: 0, right: 0, cursor: this.state.isAddMarker ? 'crosshair':'pointer' , display: "block"}}
    center={{ lat:  10.634485940638003, lng:  106.69734011202513 }} 
    zoom={18}
    doubleClickZoom={false}
    attributionControl={false}
    zoomControl={false}
    scrollWheelZoom={true}
    maxNativeZoom={19.4}
    minZoom={5} 
    maxZoom={20}
    wheelPxPerZoomLevel={500} // Tăng giá trị này để làm cho cuộn bánh xe nhanh hơn
> 

      {this.state.clickedCard ? <ChangeView center={this.state.currentView} setClicked={this.setUnClicked} zoom={20}/> : null}
      <TileLayer
        url="https://mt1.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
        attribution='&copy; <a href="">châu 0799799993</a>&copy; <a href="">OpenStreetMap</a>  &copy; <a href="">MapBox</a>'
        maxZoom={22}
        tileSize={256}
        center={{ lat: 10.60289, lng: 106.644115 }} 
        loading="lazy"
      />

  

  <SearchMapView listMapSearch={this.state.listMapSearch} mapSearch={this.state.toSearchMapText} open={this.state.openSearchMap} handleClose={() => this.setOpenSearchMap(false)} handleMapSearchChange={this.setTextSearchMap} ></SearchMapView>
  <SearchMapView1  listMapSearch={this.state.listMapSearch} mapSearch={this.state.toSearchMapText} open={this.state.openSearchMap1} handleClose={() => this.setOpenSearchMap1(false)} handleMapSearchChange={this.setTextSearchMap1} ></SearchMapView1>

<SearchMapView2 listMapSearch={this.state.listMapSearch} mapSearch={this.state.toSearchMapText} open={this.state.openSearchMap2} handleClose={() => this.setOpenSearchMap2(false)} handleMapSearchChange={this.setTextSearchMap2} ></SearchMapView2>

<SearchMapView8 listMapSearch={this.state.listMapSearch} mapSearch={this.state.toSearchMapText} open={this.state.openSearchMap8} handleClose={() => this.setOpenSearchMap8(false)} handleMapSearchChange={this.setTextSearchMap2} ></SearchMapView8>



 
      <TileLayer
        url="https://tcmap.vn:20010/data/62cangiuoc23/{z}/{x}/{y}.png"
       maxNativeZoom={15}
        minZoom={12}
       opacity={this.state.opacity}
       wheelPxPerZoomLevel={99999999999999999}
       tileSize={256}
       loading="lazy"
       /> 
 
        <MarkerClusterGroup
          onClick={(e) => console.log('onClick', e)}
          iconCreateFunction={createClusterCustomIcon}
          maxClusterRadius={800}
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={true}
          maxNativeZoom={16}
          maxZoom={17}
          minZoom={15}
          disableClusteringAtZoom={18}
          zoomToBoundsOnClick={false}
          animate={false}
          scrollWheelZoom={true}
        >
          {this.state.markers.map((data, index) => this.state.zoomLevel >= 17 && (

            
           <Marker
           animate={true}
           disableClusteringAtZoom={17}
           interactive={!this.state.isDraging}
           eventHandlers={{
             click: (e) => {
               this.setState({
                 currentView: [data.lag, data.lng],
                 clickedCard: true,
                 isMarkerDialogOpen: true,
                 currentMarker: data
               })
             },
           }}
           key={index}
           position={[data.lag, data.lng]}
           icon={L.divIcon({
             className: "leaflet-tooltip4",
             iconUrl: "./assets/icons/pint.png",
             html: `
               <div style="display: flex; flex-direction: column; align-items: center;" >
                 <div style="
                   width: auto;
                   border: 2px solid #3d94f6;
                   border-radius: 10px;
                   color: #3d94f6;
                   background-color: white;
                   height: 35px;
                   font-size: 13px;
                   margin: 5px;
                   margin-top: 3px;
                   overflow: hidden;
                   white-space: nowrap;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                     font-weight: bold;
                 ">
                   <p style="margin: 0;">&nbsp; ${data.name.slice(0, 7)}${data.name.length > 10 ? '...' : ''} &nbsp;</p>
                 </div>
               </div>
             `,
             iconSize: [100, 0],
           })}
           keepInView={true} 
         >
         </Marker>
         
          ))}
        </MarkerClusterGroup>



  

<Container 
  style={{
    top: 302,
    right:45,
    cursor: "pointer",
    background: "none",
    size: "13px",
    position: "fixed", // Thay đổi từ 'absolute' thành 'fixed' để container luôn hiển thị trên cùng của trình duyệt
    borderRadius: "5px",
    margin: "3px",
    padding: "5px 10px",
    fontSize: "small",
    width:"1px",
    zIndex: 99999999
  }}
>
 
  <LocateControl />   
    <Geoman />     

</Container>



<Container 
  style={{
    top: 32,
    left:4,
    cursor: "pointer",
    background: "none",
    size: "13px",
    position: "fixed", // Thay đổi từ 'absolute' thành 'fixed' để container luôn hiển thị trên cùng của trình duyệt
    borderRadius: "5px",
    margin: "3px",
    padding: "5px 10px",
    fontSize: "small",
    width:"1px",
    zIndex: 99999999
  }}
>
<div className="image-container" onClick = {nangcap} style={{
                position: "relative",
                backgroundImage: `url(../assets/icons/1000_F_529985028_eDc7RMZWRqT8knkwTz0RbeUgS9tlGYMM.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "5rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                justifyContent: "center"
              }}>
                   <div 
                    className="text-overlay">
                    <br/>
                    <br/>  
                  <h1 className="old-price" style={{ display :"flex",fontSize:"15px"}}>nâng cấp</h1>
                </div>
              </div>
</Container>
 
 
    </MapContainer>
  <BottomNavigation
  showLabels
  sx={{
    width: {
      xs: '100%', // 100% trên màn hình nhỏ
      sm: '80%',  // 80% trên màn hình trung bình
      md: '40%',  // 40% trên màn hình lớn
    },
    position: 'fixed',
    bottom: 0, // Luôn nằm dưới cùng
    left: '50%', // Căn giữa theo chiều ngang
    transform: 'translateX(-50%)', // Dịch chuyển để căn giữa
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-evenly', // Phân bổ đều các nút
    alignItems: 'center', // Căn giữa dọc
    height: 60,
    padding: 0,
    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  }}
>
  <BottomNavigationAction
    sx={{
      flex: '1',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    label="Search"
    onClick={this.setOpenSearchMap}
     icon={<div >
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
    </div>}
  />

  <BottomNavigationAction
    sx={{
      flex: '1',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    label="Tin BĐS"
    onClick={this.setOpenSearchMap8}
      icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
    <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5z"/>
    <path d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z"/>
  </svg></div>}
  />

  <BottomNavigationAction
    sx={{
      flex: '1',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    onClick={() => {
      if (localStorage.getItem("token") === null) {
        window.location.href = "/login";
      } else {
        this.setState({ isInfoOpen: true });
      }
    }}
    icon={
      <Avatar
        className="logo"
        src="../assets/icons/logo512.png"
        sx={{
          height: 48,
          width: 48,
          border: '2px solid GreenYellow',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
          background: "#fff",
        }}
      />
    }
  />

  <BottomNavigationAction
    sx={{
      flex: '1',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    label="La bàn"
    onClick={() => {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.href = "/login";
      } else {
        this.setState({ isAddMarker: !this.state.isAddMarker });
      }
    }}
    icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-browser-safari" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.25-14.75v1.5a.25.25 0 0 1-.5 0v-1.5a.25.25 0 0 1 .5 0m0 12v1.5a.25.25 0 1 1-.5 0v-1.5a.25.25 0 1 1 .5 0M4.5 1.938a.25.25 0 0 1 .342.091l.75 1.3a.25.25 0 0 1-.434.25l-.75-1.3a.25.25 0 0 1 .092-.341m6 10.392a.25.25 0 0 1 .341.092l.75 1.299a.25.25 0 1 1-.432.25l-.75-1.3a.25.25 0 0 1 .091-.34ZM2.28 4.408l1.298.75a.25.25 0 0 1-.25.434l-1.299-.75a.25.25 0 0 1 .25-.434Zm10.392 6 1.299.75a.25.25 0 1 1-.25.434l-1.3-.75a.25.25 0 0 1 .25-.434ZM1 8a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 0 .5h-1.5A.25.25 0 0 1 1 8m12 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 1 1 0 .5h-1.5A.25.25 0 0 1 13 8M2.03 11.159l1.298-.75a.25.25 0 0 1 .25.432l-1.299.75a.25.25 0 0 1-.25-.432Zm10.392-6 1.299-.75a.25.25 0 1 1 .25.433l-1.3.75a.25.25 0 0 1-.25-.434ZM4.5 14.061a.25.25 0 0 1-.092-.341l.75-1.3a.25.25 0 0 1 .434.25l-.75 1.3a.25.25 0 0 1-.342.091m6-10.392a.25.25 0 0 1-.091-.342l.75-1.299a.25.25 0 1 1 .432.25l-.75 1.3a.25.25 0 0 1-.341.09ZM6.494 1.415l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M9.86 13.972l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M3.05 3.05a.25.25 0 0 1 .354 0l.353.354a.25.25 0 0 1-.353.353l-.354-.353a.25.25 0 0 1 0-.354m9.193 9.193a.25.25 0 0 1 .353 0l.354.353a.25.25 0 1 1-.354.354l-.353-.354a.25.25 0 0 1 0-.353M1.545 6.01l.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.482Zm12.557 3.365.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.483m-12.863.436a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177m12.557-3.365a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177M3.045 12.944a.3.3 0 0 1-.029-.376l3.898-5.592a.3.3 0 0 1 .062-.062l5.602-3.884a.278.278 0 0 1 .392.392L9.086 9.024a.3.3 0 0 1-.062.062l-5.592 3.898a.3.3 0 0 1-.382-.034zm3.143 1.817a.25.25 0 0 1-.176-.306l.129-.483a.25.25 0 0 1 .483.13l-.13.483a.25.25 0 0 1-.306.176M9.553 2.204a.25.25 0 0 1-.177-.306l.13-.483a.25.25 0 1 1 .483.13l-.13.483a.25.25 0 0 1-.306.176"/>
  </svg></div>}
  />

  <BottomNavigationAction
    sx={{
      flex: '1',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    label="QH mới"
    onClick={() => {
      this.NewMapNotification(true);
    }}
     icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 576 512"><path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg></div>}
  />
</BottomNavigation>







          </div>
          
      
 
   
        {this.state.currentMarker != null ? <DialogMarker state={this.state} handleClose={this.handleMarkerDialogClose}> 
        </DialogMarker>: null}
        


      

        {this.state.userData != null ? <InfoDialog
        userData = {this.state.userData}
        isInfoOpen = {this.state.isInfoOpen}
        isChangePassword = {this.state.isChangePassword}
        handleClose={this.handleClose} 
      />: null}
   
        {
          (this.state.isSuperUser) ? null : <NewMapNotification
          open={this.state.openNewMap}
          handleClose={this.handleCloseNewMap}
          data={this.state.dataNotification}
          >
  
          </NewMapNotification>
        }
        <Snackbar open={this.state.openConfirmAlert} autoHideDuration={6000} onClose={this.handleCloseConfirmAlert}>
          <Alert onClose={this.handleCloseConfirmAlert} severity="success" sx={{ width: '100%' }}>
          đã đăng tin thành công.
          </Alert>
        </Snackbar>
        
                
 

{/* <Container 
 maxWidth="xl" className="old-price"  style={{zIndex: 0, position: 'absolute',  top: "1rem" ,height: "0.1px",color : " blue" ,width:"100%",}}>
<marquee direction="left" 
               maxWidth="xl"
                 
                 scrollamount="2" loop="50" scrolldelay="0" onmouseover="this.stop()" onmouseout="this.start()"
                 > 👉  đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.          
             
                              </marquee>
                              
                              


                              
          
                              

</Container>   */}


 

          <input  style={{ top: 150 , right: -25, cursor: "pointer" , color : "red" ,background : "white" ,size: "10px" , borderRadius:"30px", margin: "3px", }} 
          type="range"
          className="slider-line"
          min="0"
          max="100"
          value={this.state.tempOpacity}
          onChange={this.handleOpacityChange}
          onMouseUp={this.applyOpacity} // Khi người dùng thả chuột
          onTouchEnd={this.applyOpacity} // Hỗ trợ cảm ứng trên thiết bị di động
        />
           
 
               
      
      </div> 
    );
  }
}

export default HomePage;











