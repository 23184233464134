import React, { useRef, useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import maplibre from 'maplibre-gl';
import * as turf from '@turf/turf';
import './MapComponent.css';
import SearchMapView2 from '../../components/laban';
import SearchMapView1 from '../../components/SearchMapView1';
import SearchMapView8 from '../../components/SearchMapView8';
import SearchBar from './SearchBar'; // Thêm SearchBar
import BottomNavComponent from '../../components/BottomNav';
import { ButtonBase, FormControl, Label, Box, Tooltip, Avatar, Menu, MenuItem, Typography, Container, IconButton, Badge, Stack, Divider, CardMedia, Button, Switch } from "@mui/material";
import { Utils } from "../../Utils/Utils";
import { Slider } from "@mui/material";
 import Supercluster from 'supercluster';
import { Services } from '../../APIs/Services';
import initializeMapDraw from './mapDraw';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

  const styles = {
    iconButton: {
      border: "1px solid #ccc", // Thêm border nếu muốn
      borderRadius: "5px", // Làm tròn góc
      padding: "8px", // Khoảng cách trong nút
    },
  };

const MapComponent = forwardRef(({ setNotification, setSelectedFeature, isOpenMaps, setIsOpenMaps, maps, sendAlert }, ref) => {
   const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mapContainer = useRef(null);
  const map = useRef(null);
  const searchGeoJSON = useRef(null);
  const selectedFeatureId = useRef(null);
  const [modals, setModals] = useState({ isOpen1: false, isOpen2: false, isOpen8: false });
  const toggleModal = (key) => setModals((prev) => ({ ...prev, [key]: !prev[key] }));
  const [layers, setLayers] = useState({
    raster1: true, // Mặc định hiển thị raster1
    raster2: false, // Mặc định ẩn raster2
    raster3: false, // Mặc định ẩn raster3
  });
  const [dataNotification, setDataNotification] = useState([]);
    const [currentView, setCurrentView] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [zoomMap, setZoomMap] = useState(); // Giá trị mặc định
  const [centerMap, setCenterMap] = useState(null); // Giá trị mặc định
  const [mapBound, setMapBound] = useState(null);
  const [openNewMap, setOpenNewMap] = useState(false);
  const [watchId, setWatchId] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const geolocateControl = useRef(null);
 const drawRef = useRef(null); 
    const [initialCenter, setInitialCenter] = useState(null);
  const [initialZoom, setInitialZoom] = useState(null);

 const [isMeasuring, setIsMeasuring] = useState(false);
const [markers, setMarkers] = useState([]);
const supercluster = useRef(
    new Supercluster({
      radius: 40, // Bán kính gom cụm
      maxZoom: 16, // Zoom tối đa để thực hiện clustering
      minZoom: 0, // Zoom tối thiểu để thực hiện clustering
    })
  );
const [isDrawing, setIsDrawing] = useState(false); // Kiểm soát chế độ đo



    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await Services.getSettingNewsMap("");
          const data = response.data;
    
          if (data.data !== null) {
            const arr = data.data.filter((value) => value.is_active === true);
    
            if (arr.length > 0) {
              // Không mở modal ngay lúc này
              // setOpenNewMap(true);
              setDataNotification(arr);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []); // Empty dependency array means this effect runs once after the initial render
  
     useEffect(() => {
        Services.getMarkers().then((data) => {
          setCurrentView(data.data.data);
          handleCurrentViewChange(data.data.data);
        });
      }, []);
    
      useEffect(()=>{ 
        Services.getMarkers().then(data => {
          setMarkers(data.data.data)
        })
      },[])
  const handleCurrentViewChange = (currentView) => {
    Services.getCardMarkers(currentView).then((data) => {
    });
  };
  
    useEffect(() => {
      Services.getMarkers().then((data) => {
        handleCurrentViewChange(data.data.data);
      });
    }, []);
  
    useEffect(()=>{ 
      Services.getMarkers().then(data => {
        setMarkers(data.data.data)
      })
    },[])
  
  
  
  const draw = useRef(null); // Ref để lưu instance MapboxDraw
  const [mapInitialized, setMapInitialized] = useState(false);
 
 
  
  const handleClear = () => {
    if (draw) {
      draw.trash(); // Xóa các hình đã vẽ
    }
  };




  const [isOpen2, setIsOpen2] = useState(false);
  const handleOpenModal2 = () => {
    setIsOpen2(true);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleOpenModal1 = () => {
    setIsOpen1(true);
  };

  const [isOpen8, setIsOpen8] = useState(false);
  const handleOpenModal8 = () => {
    setIsOpen8(true);
  };

  const [openPinAlert, setOpenPinAlert] = useState(false);

  const handleOpenPinAlert = () => {
    setOpenPinAlert(true);
  };
  const handleClosePinAlert = () => {
    setOpenPinAlert(false);
  };


  const handleOpenMapModal = () => {
    setOpenNewMap(true);
  };
  
  const handleCloseMapModal = () => {
    setOpenNewMap(false);
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  // Hàm handleZoom để xử lý việc zoom tới tọa độ nhập vào từ SearchBar
  const handleZoom = (lat, lng) => {
    if (map.current) {
      map.current.flyTo({
        center: [lng, lat],
        zoom: 18, // Mức zoom có thể tùy chỉnh
        speed: 1.2, // Tốc độ di chuyển
        essential: true, // Điều này đảm bảo rằng hoạt động quan trọng và không bị can thiệp bởi người dùng
      });

      // Bạn cũng có thể thêm marker tại vị trí đó nếu muốn
      if (userMarker) {
        userMarker.setLngLat([lng, lat]);
      } else {
        const marker = new maplibre.Marker()
          .setLngLat([lng, lat])
          .addTo(map.current);
        setUserMarker(marker);
      }

      setNotification({ message: `Zoom tới vị trí (${lat}, ${lng}) thành công.`, type: 'success' });
    } else {
      setNotification({ message: 'Bản đồ chưa được khởi tạo.', type: 'error' });
    }
  };

  useImperativeHandle(ref, () => ({
    searchFeature: (to, thua) => {
      if (!searchGeoJSON.current || !searchGeoJSON.current.features) {
        setNotification({ message: 'Dữ liệu tìm kiếm chưa được tải hoặc không hợp lệ.', type: 'error' });
        return;
      }
      const matchedFeatures = searchGeoJSON.current.features.filter((feature) => {
        const shbando = feature.properties.SHBANDO;
        if (shbando) {
          const [toMatch, thuaMatch] = [shbando.match(/to\s*(\d+)/i), shbando.match(/thua\s*(\d+)/i)];
          if (toMatch && thuaMatch) {
            return parseInt(toMatch[1], 10) === parseInt(to, 10) && parseInt(thuaMatch[1], 10) === parseInt(thua, 10);
          }
        }
        return false;
      });

      if (matchedFeatures.length > 0) {
        flyToFeature(matchedFeatures[0]);
        simulateClickOnFeature(matchedFeatures[0]);
        setNotification({ message: `Tìm thấy ${matchedFeatures.length} kết quả. Hiển thị kết quả đầu tiên.`, type: 'success' });
      } else {
        setNotification({ message: 'Không tìm thấy kết quả phù hợp.', type: 'error' });
      }
    },
  }));

  useEffect(() => {
    fetch(`${Utils.URL_SERVER}/data/${Utils.DATA_JSON_NAME_FILE}.json`) // URL tới tệp JSON cấu hình
      .then(response => response.json())
      .then(data => {
        setMapData(data);
        const [lng, lat, minzoom] = data.center;
        setCenterMap([lng, lat]);
        setZoomMap(minzoom);
           setInitialCenter([lng, lat]);
          setInitialZoom(minzoom);
        // Nếu cần, cập nhật mapBound từ data.bounds
      })
      
      .catch(error => {
        setNotification({ message: 'Lỗi tải dữ liệu bản đồ.', type: 'error' });
      });
  }, [setNotification]);

  const [opacity, setOpacity] = useState(100); // Giá trị hiển thị trên thanh trượt
  const [finalOpacity, setFinalOpacity] = useState(100); // Giá trị áp dụng cho bản đồ
  const sliderRef = useRef(null); // Tham chiếu đến thanh trượt

  const handleSliderChange = (e) => {
    const newOpacity = Number(e.target.value);
    setOpacity(newOpacity); // Cập nhật giá trị hiển thị
  };

  const applyOpacity = () => {
    setFinalOpacity(opacity); // Lưu giá trị cuối cùng
    if (map.current) {
      // Cập nhật độ trong suốt cho raster1
      if (map.current.getLayer('raster1')) {
        map.current.setPaintProperty('raster1', 'raster-opacity', opacity / 100);
      }
      // Cập nhật độ trong suốt cho raster2
      if (map.current.getLayer('raster2')) {
        map.current.setPaintProperty('raster2', 'raster-opacity', opacity / 100);
      }
      if (map.current.getLayer('raster3')) {
        map.current.setPaintProperty('raster3', 'raster-opacity', opacity / 100);
      }
    }
  };

 

  const resetMapView = () => {
    if (map.current && initialCenter && initialZoom !== null) {
      map.current.flyTo({
        center: initialCenter,
        zoom: initialZoom,
        speed: 1.2, // Tốc độ di chuyển
        essential: true, // Điều này đảm bảo rằng hoạt động quan trọng và không bị can thiệp bởi người dùng
      });

      // Nếu bạn muốn loại bỏ marker người dùng khi quay lại vị trí ban đầu
      if (userMarker) {
        userMarker.remove();
        setUserMarker(null);
      }

      // Clear measurement labels
      if (map.current.getSource('measurement-labels')) {
        map.current.getSource('measurement-labels').setData({
          type: 'FeatureCollection',
          features: []
        });
      }

      setSelectedFeature(null);
      setNotification({ message: 'Đã quay lại vị trí ban đầu.', type: 'success' });
    } else {
      setNotification({ message: 'Không thể quay lại vị trí ban đầu.', type: 'error' });
    }
  };



  useEffect(() => {
    if (map.current || !mapData) {
      return;
    }

    const id = mapData.vector_layers[0].id; // Lấy id từ vector_layers
    const [longitude, latitude, minzoom] = mapData.center;

    map.current = new maplibre.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        sources: {
          'raster': {
            type: 'raster',
            tiles: ['https://mt1.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}'],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: 'raster-layer',
            type: 'raster',
            source: 'raster',
            minzoom: 0,
            maxzoom: 22,
          },
          // Các lớp khác...
        ],
        center: [longitude, latitude],
        zoom: minzoom,
        bounds: mapBound,
      },
      zoom: minzoom,
      center: [longitude, latitude],
      bounds: mapBound,
      attributionControl: false,
    });
   
    // Initialize the GeolocateControl without restrictive fitBoundsOptions
    geolocateControl.current = new maplibre.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
      showAccuracyCircle: false, // Optional: Hide accuracy circle
    });

    // Add the GeolocateControl to the map at the desired position
    map.current.addControl(geolocateControl.current, 'top-left');

    // Add an event listener for the 'geolocate' event
    geolocateControl.current.on('geolocate', (e) => {
      const { longitude, latitude } = e.coords;

      map.current.flyTo({
        center: [longitude, latitude],
        zoom: 18,
        speed: 1.2,
        essential: true,
      });
    });

// Render clusters
 map.current.on('moveend', () => {
  const bounds = map.current.getBounds();

  fetch(`/api/markers?bounds=${bounds.toString()}`)
    .then((res) => res.json())
    .then((markers) => {
      // Clear existing markers
      map.current.clear();

      // Add new markers
      markers.forEach(({ lat, lng, title }) => {
        new maplibre.Marker()
          .setLngLat([lng, lat])
          .setPopup(new maplibre.Popup().setHTML(`<h3>${title}</h3>`))
          .addTo(map.current);
      });
    });
});


  setMapInitialized(false);
    map.current.on('load', () => {
      // Thêm các nguồn và lớp raster
   
      
      map.current.addSource('raster1', {
        type: 'raster',
        tiles: [`${Utils.URL_IMAGE}`],
        tileSize: 256,
        minzoom: 11,
        maxzoom: 15,
      });

      map.current.addLayer({
        id: 'raster1',
        type: 'raster',
        source: 'raster1',
        paint: {
          'raster-opacity': opacity / 100,
        },
      });

      // Thêm các lớp raster bổ sung (ẩn mặc định)
      ['raster2', 'raster3'].forEach((raster, index) => {
        const urls = [Utils.URL_IMAGE3, Utils.URL_IMAGE4];
        map.current.addSource(raster, {
          type: 'raster',
          tiles: [urls[index]],
          tileSize: 256,
          minzoom: 0,
          maxzoom: 19,
        });

        map.current.addLayer({
          id: raster,
          type: 'raster',
          source: raster,
          paint: {
            'raster-opacity': opacity / 100,
          },
          layout: {
            visibility: 'none', // Ẩn ban đầu
          },
        });
      });

      // Thêm nguồn vector
      map.current.addSource('vector-source', {
        type: 'vector',
        tiles: [`${Utils.CAN_GIUOC_URL_MAPBOX_VECTOR_TILE}`],
        minzoom: 16,
        maxzoom: 20,
        promoteId: 'SHTHUA',
      });

      // Thêm lớp vector
      map.current.addLayer({
        id: 'vector-layer',
        type: 'fill',
        source: 'vector-source',
        'source-layer': `${id}`,
        paint: {
          'fill-opacity': 0,
          'fill-outline-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            'red',
            'green',
          ],
        },
      }, 'raster-layer');

      // Thêm lớp biểu tượng cho vector
      map.current.addLayer({
        id: 'vector-label-layer',
        type: 'symbol',
        source: 'vector-source',
        'source-layer': `${id}`,
        layout: {
          'symbol-placement': 'point',
          'text-field': [
            'format',
            ['get', 'SHBANDO'], { 'font-scale': 0.9, 'text-color': '#2013ec' },
            '\n', {},
            ['get', 'SHTHUA'], { 'font-scale': 1, 'text-color': '#13e2ec' }
          ],
          'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
          'text-anchor': 'center',
          'text-justify': 'center',
          'text-size': [
            'interpolate',
            ['linear'],
            ['get', 'area'],
            0, 8,
            1000, 12,
            5000, 16
          ],
          'text-allow-overlap': false,
          'text-ignore-placement': false,
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': 'rgba(255, 255, 255, 0.8)',
          'text-halo-width': 2,
        },
        minzoom: 18.5,
        maxzoom: 24
      });

     
      map.current.addLayer({
        id: 'selected-outline',
        type: 'line',
        source: 'vector-source',
        'source-layer': `${id}`,
        layout: {},
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            'red', // Màu đỏ khi được chọn
            '#113dc5', // Màu xanh lá mặc định
          ],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            4, // Độ rộng lớn hơn khi được chọn
            1, // Độ rộng mặc định
          ],
        },
        beforeId: 'vector-labels-layer',
      });

      // Fly to feature on map load if needed
    });
 
  const { draw, cleanup } = initializeMapDraw(map.current, setNotification);
    drawRef.current = draw; // Store the draw instance in ref
  map.current.on('click', 'vector-layer', (e) => {
       if (e.features && e.features.length > 0) {
         const feature = e.features[0];
         flyToFeature(feature);
 
         // Unselect previously selected feature
         if (selectedFeatureId.current !== null) {
           map.current.setFeatureState(
             { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
             { selected: false }
           );
         }
 
         // Select the new feature
         selectedFeatureId.current = feature.id;
         map.current.setFeatureState(
           { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
           { selected: true }
         );
         flyToFeature(feature);
 
         handleFeatureSelection(feature, e.lngLat);
 
         if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
           const area = turf.area(feature);
           const centroid = turf.centroid(feature);
 
           const edges = feature.geometry.coordinates[0].map((coord, index, coords) => {
             if (index < coords.length - 1) {
               const line = turf.lineString([coord, coords[index + 1]]);
               return {
                 length: turf.length(line, { units: 'meters' }),
                 midpoint: turf.midpoint(turf.point(coord), turf.point(coords[index + 1])),
               };
             }
             return null;
           }).filter(Boolean);
 
           const lengthGeoJSON = {
             type: 'FeatureCollection',
             features: edges.map(edge => ({
               type: 'Feature',
               geometry: edge.midpoint.geometry,
               properties: { length: `${edge.length.toFixed(2)} m` },
             })),
           };
 
           const areaGeoJSON = {
             type: 'FeatureCollection',
             features: [{
               type: 'Feature',
               geometry: centroid.geometry,
               properties: { area: `${area.toFixed(2)} m²` },
             }],
           };
 
           const featureId = e.features[0].id;
 
           // Xóa trạng thái "selected" khỏi các đối tượng khác
           const allFeatures = map.current.queryRenderedFeatures({ layers: ['vector-layer'] });
           allFeatures.forEach((feature) => {
             if (feature.id !== featureId) {
               map.current.setFeatureState(
                 { source: 'vector-source', sourceLayer: `${id}`, id: feature.id },
                 { selected: false }
               );
             }
           });
 
           // Đặt trạng thái "selected" cho đối tượng được click
           map.current.setFeatureState(
             { source: 'vector-source', sourceLayer: `${id}`, id: featureId },
             { selected: true }
           );
 
           // Remove previous labels if any
           if (map.current.getSource('length-labels')) {
             map.current.removeLayer('length-labels-layer');
             map.current.removeSource('length-labels');
           }
 
           if (map.current.getSource('area-label')) {
             map.current.removeLayer('area-label-layer');
             map.current.removeSource('area-label');
           }
 
           // Add new labels
           map.current.addSource('length-labels', { type: 'geojson', data: lengthGeoJSON });
           map.current.addSource('area-label', { type: 'geojson', data: areaGeoJSON });
 
        
         }
       }
     });
    
    const handleVectorClick = (e) => {
    if (e.features && e.features.length > 0) {
        const feature = e.features[0];
        flyToFeature(feature);

        // Unselect previously selected feature
        if (selectedFeatureId.current !== null) {
            map.current.setFeatureState(
                { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
                { selected: false }
            );
        }

        // Select the new feature
        selectedFeatureId.current = feature.id;
        map.current.setFeatureState(
            { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
            { selected: true }
        );

        handleFeatureSelection(feature, e.lngLat);
    }
};

 

map.current.on('touchstart', 'vector-layer', (e) => {
    if (isMeasuring) {
        setNotification({ message: 'Hoàn tất đo trước khi chọn vector.', type: 'warning' });
        return;
    }
    handleVectorClick(e);
});

// Sự kiện thay đổi chế độ đo
map.current.on('draw.modechange', (e) => {
    const isDrawingMode = e.mode === 'draw_polygon' || e.mode === 'draw_line_string';
    setIsMeasuring(isDrawingMode);

    if (isDrawingMode) {
        map.getCanvas().style.cursor = 'crosshair';

        // Tắt sự kiện click/touch khi đang đo
        map.current.off('click', 'vector-layer', handleVectorClick);
        map.current.off('touchstart', 'vector-layer', handleVectorClick);
    } else {
        map.getCanvas().style.cursor = '';

        // Bật lại sự kiện click/touch khi thoát chế độ đo
        map.current.on('click', 'vector-layer', handleVectorClick);
        map.current.on('touchstart', 'vector-layer', handleVectorClick);
    }
});



    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [mapData, mapBound]);
  

  useEffect(() => {
     fetch(`${Utils.URL_SEARCH}`)
       .then((res) => res.json())
       .then((data) => {
         searchGeoJSON.current = data;
       })
       .catch(() => {
         setNotification({ message: 'Lỗi tải dữ liệu tìm kiếm.', type: 'error' });
       });
  }, [setNotification]);
  

 const flyToFeature = (feature) => {
  if (!feature || !feature.geometry) return;

  // Tính bounding box của feature
  const bbox = turf.bbox(feature); // [minLng, minLat, maxLng, maxLat]

  // Tính kích thước bbox
  const [minLng, minLat, maxLng, maxLat] = bbox;
  const bboxWidth = Math.abs(maxLng - minLng); // Độ rộng bbox
  const bboxHeight = Math.abs(maxLat - minLat); // Độ cao bbox

  // Lấy diện tích của feature
  const area = turf.area(feature); // Diện tích tính bằng m²

   
  // Ước lượng mức zoom dựa trên diện tích và loại thiết bị
  let estimatedZoom;
  if (area < 500) {
    estimatedZoom = isMobile ? 19 : 20; // Mobile zoom gần hơn
  } else if (area < 2000) {
    estimatedZoom = isMobile ? 18 : 19;
  } else {
    estimatedZoom = isMobile ? 17 : 17;
  }

  // Tính toán padding
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const padding = isMobile
    ? {
        top: screenHeight * 0.15, // Mobile padding lớn hơn
        bottom: 300 + screenHeight * 0.05, // Thêm 300px cho bảng thuộc tính
        left: screenWidth * 0.2, // Mobile padding ngang lớn hơn
        right: screenWidth * 0.2,
      }
    : {
        top: screenHeight * 0.1,
        bottom: screenHeight * 0.1,
        left: screenWidth * 0.1,
        right: screenWidth * 0.1,
      };

  console.log(`BBox Width: ${bboxWidth}, BBox Height: ${bboxHeight}`);
  console.log(`Area: ${area} m²`);
  console.log(`Estimated Zoom: ${estimatedZoom} (Mobile: ${isMobile})`);
  console.log(`Padding:`, padding);

  // Sử dụng fitBounds để zoom tới vector
  map.current.fitBounds(bbox, {
    padding: padding, // Áp dụng padding
    maxZoom: estimatedZoom, // Áp dụng mức zoom tối ưu
    duration: isMobile ? 1500 : 1000, // Thời gian chuyển động dài hơn trên mobile
  });
};



  const simulateClickOnFeature = (feature) => {
    const bbox = turf.bbox(feature);

    if (!bbox || bbox.length < 4) {
      console.error("Invalid bounding box for feature.");
      return;
    }

    const coordinates = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];

    // Sử dụng sự kiện 'moveend' để thực hiện các thao tác sau khi flyTo hoàn tất
    map.current.once('moveend', () => {
      // Sau khi zoom vào và di chuyển tới feature, tìm và click vào feature
      const point = map.current.project(coordinates); // Chuyển đổi tọa độ thành điểm trên bản đồ
      const features = map.current.queryRenderedFeatures(point, { layers: ['vector-layer'] });

      if (features.length > 0) {
        const clickedFeature = features[0]; // Chọn feature đầu tiên từ kết quả query

        // Nếu đã có feature được chọn trước đó, bỏ chọn nó
        if (selectedFeatureId.current !== null) {
          map.current.setFeatureState(
            { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
            { selected: false }
          );
        }

        selectedFeatureId.current = clickedFeature.id; // Cập nhật feature đã chọn
        map.current.setFeatureState(
          { source: 'vector-source', sourceLayer: `${Utils.DATA_JSON_NAME_FILE}`, id: selectedFeatureId.current },
          { selected: true }
        );

        handleFeatureSelection(clickedFeature, coordinates); // Thực hiện các thao tác sau khi chọn feature

      }
    });
  };

  const updateEdgeLabels = (features) => {
    const lines = [];
    const areas = [];

    features.forEach((feature) => {
      const geometry = feature.geometry;

      if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
        const polygons = geometry.type === 'Polygon' ? [geometry.coordinates] : geometry.coordinates;
        polygons.forEach((polygon) => {
          const coordinates = polygon[0];

          // Calculate area
          const area = turf.area(turf.polygon([coordinates]));
          const areaDisplay = `${area.toFixed(2)} m²`;

          // Calculate centroid for area label
          const centroid = turf.centroid(turf.polygon([coordinates]));
          areas.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: centroid.geometry.coordinates,
            },
            properties: {
              area: areaDisplay,
            },
          });

          // Create LineStrings for each edge
          for (let i = 0; i < coordinates.length - 1; i++) {
            const start = coordinates[i];
            const end = coordinates[i + 1];
            const edge = turf.lineString([start, end]);
            const length = turf.length(edge, { units: 'meters' });
            const lengthDisplay = length < 1 ? '<1 m' : `${length.toFixed(2)} m`;

            // Set zoom levels for edges with length >= 2m
            const minZoom = length >= 2 ? 5 : null;
            const maxZoom = length >= 2 ? 18 : null;

            lines.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: edge.geometry.coordinates,
              },
              properties: {
                length: lengthDisplay,
                minZoom: minZoom,
                maxZoom: maxZoom,
              },
            });
          }
        });
      } else if (geometry.type === 'LineString' || geometry.type === 'MultiLineString') {
        const linestrings = geometry.type === 'LineString' ? [geometry.coordinates] : geometry.coordinates;
        linestrings.forEach((coords) => {
          for (let i = 0; i < coords.length - 1; i++) {
            const start = coords[i];
            const end = coords[i + 1];
            const edge = turf.lineString([start, end]);
            const length = turf.length(edge, { units: 'meters' });
            const lengthDisplay = `${length.toFixed(2)} m`;

            // Set zoom levels for edges with length >= 2m
            const minZoom = length >= 2 ? 5 : null;
            const maxZoom = length >= 2 ? 18 : null;

            lines.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: edge.geometry.coordinates,
              },
              properties: {
                length: lengthDisplay,
                minZoom: minZoom,
                maxZoom: maxZoom,
              },
            });
          }
        });
      }
    });

    // Remove existing label layers
    removeLayerIfExists('edge-labels-layer');
    removeLayerIfExists('area-labels-layer');

    // Add edge labels layer
    if (lines.length > 0) {
      map.current.addLayer({
        id: 'edge-labels-layer',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lines,
          },
        },
        layout: {
          'symbol-placement': 'line-center',
          'text-field': ['get', 'length'],
          'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
          'text-size': 15,
          'text-padding': 5,
          'text-max-angle': 45,
          'text-keep-upright': true,
          'text-allow-overlap': true,
          'text-ignore-placement': false,
        },
        paint: {
          'text-color': 'black',
          'text-halo-color': 'rgba(255, 255, 255, 0.9)',
          'text-halo-width': 2,
        },
        minzoom: 15,
        maxzoom: 22,
      });
    }

    // Add area labels layer
    if (areas.length > 0) {
      map.current.addLayer({
        id: 'area-labels-layer',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: areas,
          },
        },
        layout: {
          'symbol-placement': 'point',
          'text-field': ['get', 'area'],
          'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
          'text-anchor': 'center',
          'text-justify': 'center',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15, 12,
            16, 14,
            17, 16,
            18, 16,
            19, 16,
            20, 16,
            21, 16,
            22, 16,
          ],
          'text-padding': 5,
          'text-max-angle': 45,
          'text-keep-upright': true,
          'text-allow-overlap': true,
          'text-ignore-placement': false,
        },
        paint: {
          'text-color': 'blue',
          'text-halo-color': 'rgba(255, 255, 255, 0.9)',
          'text-halo-width': 2,
        },
        minzoom: 15,
        maxzoom: 22,
      });
    }

    // Optional: Debugging information
    console.log(`Added ${lines.length} edge labels and ${areas.length} area labels.`);
  };

  // Function to handle feature selection and display info panel
  const handleFeatureSelection = (feature, lngLat) => {
    // Calculate area and length if applicable
    const geojson = {
      type: 'Feature',
      geometry: feature.geometry,
      properties: feature.properties,
    };

    let areaSquareMeters = null;
    if (geojson.geometry.type === 'Polygon' || geojson.geometry.type === 'MultiPolygon') {
      const area = turf.area(geojson);
      areaSquareMeters = area.toFixed(2);
    }

    let totalLength = null;
    if (geojson.geometry.type === 'LineString' || geojson.geometry.type === 'MultiLineString') {
      totalLength = turf.length(geojson, { units: 'meters' });
    }

    // Update edge and area labels
    updateEdgeLabels([geojson]);

    // Create info panel content
    let panelContent = '<div class="popup-content">';
    for (const key in feature.properties) {
      if (feature.properties.hasOwnProperty(key)) {
        panelContent += `<strong>${key}:</strong> ${feature.properties[key]}<br/>`;
      }
    }

    const { properties } = feature;
    setSelectedFeature({
      ...properties, // Truyền tất cả các thuộc tính
      coordinates: {
        lng: lngLat.lng,
        lat: lngLat.lat,
      },
    });
  };

  const toggleLayer = (layerId) => {
    Object.keys(layers).forEach((id) => {
      const visibility = id === layerId ? 'visible' : 'none';
      map.current.setLayoutProperty(id, 'visibility', visibility);
    });

    // Cập nhật trạng thái hiển thị
    setLayers((prev) => {
      const updatedLayers = {};
      Object.keys(prev).forEach((id) => {
        updatedLayers[id] = id === layerId; // Chỉ lớp được chọn là `true`
      });
      return updatedLayers;
    });
  };

  const removeLayerIfExists = (layerId) => {
    if (map.current.getLayer(layerId)) {
      map.current.removeLayer(layerId);
    }
    if (map.current.getSource(layerId)) {
      map.current.removeSource(layerId);
    }
  };

const handleDrawPolygon = () => {
  if (drawRef.current) {
    drawRef.current.changeMode('draw_polygon');
    setIsMeasuring(true); // Bật chế độ đo
    setNotification({ message: 'Chế độ đo Polygon đã được kích hoạt.', type: 'info' });
  } else {
    setNotification({ message: 'Draw control chưa được khởi tạo.', type: 'error' });
  }
};

const handleClearDrawings = () => {
  if (drawRef.current) {
    drawRef.current.trash(); // Xóa tất cả bản vẽ
    setIsMeasuring(false); // Tắt chế độ đo
    setNotification({ message: 'Tất cả bản vẽ đã được xóa.', type: 'success' });
  } else {
    setNotification({ message: 'Draw control chưa được khởi tạo.', type: 'error' });
  }
};



  const [isClicked, setIsClicked] = useState(false);
  return (
    <>
      <div ref={mapContainer} className="map-container" />
      <div>
      </div>
<Container maxWidth="100%" style={{ pointerEvents: 'none', top: 220, right: 10, display: "flex" }}>
  <Box
    sx={{ flexGrow: 0 }}
    style={{
      zIndex: 0,
      position: 'absolute',
      pointerEvents: 'all',
      right: 10,
      display: "flex",
      top: 110,
      flexDirection: 'column', // Đặt các phần tử theo hàng dọc
      alignItems: 'center', // Căn giữa theo chiều ngang
      gap: '15px', // Tạo khoảng cách giữa các phần tử
    }}
  >
    {/* Slider */}
    <Slider
      value={opacity}
      onChange={handleSliderChange}
      onMouseUp={applyOpacity}
      onTouchEnd={applyOpacity}
      valueLabelDisplay="auto"
      orientation="vertical" // Chuyển slider thành dọc
      style={{
        height: '90px', // Chiều cao của slider
        margin: '0 auto', // Căn giữa slider
      }}
    />
    {/* Nút IconButton */}
    <Tooltip title="Vị trí hiện tại">
      <IconButton
        onClick={() => geolocateControl.current.trigger()}
        style={{
          cursor: "pointer",
          background: "#ffffff",
          border: "none",
          borderRadius: "50%",
          width: "35px",
          height: "35px",
          boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        
        }}
      >
        <svg
          title="Bạn có muốn sử dụng vị trí hiện tại có quy hoạch?"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill={isClicked ? "#ffffff" : "black"}
          viewBox="0 0 16 16"
        >
          <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
        </svg>
      </IconButton>
    </Tooltip>

    {/* Các nút chức năng */}
    <div
      style={{
        display: 'flex',
        flexDirection: 'column', // Đặt các nút theo hàng dọc
        alignItems: 'center', // Căn giữa nút theo chiều ngang
        gap: '10px', // Khoảng cách giữa các nút
      }}
    >
      <div
      style={{
        display: "flex",
        flexDirection: "column", // Hiển thị theo hàng dọc
        alignItems: "center", // Căn giữa nút
        gap: "0px", // Không có khoảng cách giữa các nút
      }}
    >
      {/* Nút Đo diện tích */}
      <Tooltip title="Đo diện tích">
        <IconButton
          onClick={handleDrawPolygon}
          style={{
            ...styles.iconButton,
            backgroundColor: isMeasuring ? "#f0f0f0" : "#fff", // Thay đổi màu nền khi bật
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pentagon"
            viewBox="0 0 16 16"
          >
            <path d="M7.685 1.545a.5.5 0 0 1 .63 0l6.263 5.088a.5.5 0 0 1 .161.539l-2.362 7.479a.5.5 0 0 1-.476.349H4.099a.5.5 0 0 1-.476-.35L1.26 7.173a.5.5 0 0 1 .161-.54l6.263-5.087Zm8.213 5.28a.5.5 0 0 0-.162-.54L8.316.257a.5.5 0 0 0-.631 0L.264 6.286a.5.5 0 0 0-.162.538l2.788 8.827a.5.5 0 0 0 .476.349h9.268a.5.5 0 0 0 .476-.35l2.788-8.826Z" />
          </svg>
        </IconButton>
      </Tooltip>

      {/* Nút Xóa */}
      <Tooltip title="Xóa">
        <IconButton
          onClick={handleClearDrawings}
          style={{
            ...styles.iconButton,
            backgroundColor: "#fff", // Màu nền
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
          </svg>
        </IconButton>
      </Tooltip>
    </div>

            

             
      <Tooltip title="Xóa">
         
         <IconButton
          className="'codepro-custom-btn codepro-btn-58"
          style={{
            padding: '0',
            backgroundColor: 'none',
            color: '#FFF',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setIsOpenMaps(!isOpenMaps);
          }}
        >
          {isOpenMaps ? (
            <Avatar
              src="../assets/icons/close.png"
              className="'codepro-custom-btn codepro-btn-58"
              
            />
          ) : (
            <Avatar
              className="'codepro-custom-btn codepro-btn-58"
              
              src="../assets/icons/maps.png"
            />
          )}
        </IconButton>
            </Tooltip>
    </div>
  </Box>
</Container>



     <SearchBar
        onSearch={(to, thua) => {
          if (ref.current) {
            ref.current.searchFeature(to, thua);
          }
        }}
        setNotification={setNotification}
        onZoom={handleZoom}
        onResetView={resetMapView} // Truyền hàm reset vào SearchBar
      
      />

      <SearchMapView2
        open={isOpen2}
        handleClose={() => setIsOpen2(false)}
      />

      <SearchMapView1
        open={isOpen1}
        handleClose={() => setIsOpen1(false)}
      />

      <SearchMapView8
        open={isOpen8}
        handleClose={() => setIsOpen8(false)}
      />

      <BottomNavComponent
        handleClosePinAlert={handleClosePinAlert}
        handleOpenModal1={handleOpenModal1}
        handleOpenModal8={handleOpenModal8}
        handleOpenPinAlert={handleOpenPinAlert}
        handleOpenModal2={handleOpenModal2}
        handleOpenMapModal={handleOpenMapModal}
        layers={layers} toggleLayer={toggleLayer} 
      />
    </>
  );
});

export default MapComponent;
