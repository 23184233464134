import React, { useEffect } from 'react';
import './Notification.css';

const Notification = ({ message, type }) => {
  useEffect(() => {
    if (!message) return; // Only set up the timer if there's a message

    const timer = setTimeout(() => {
      // Dispatch an event to clear the notification after 3 seconds
      window.dispatchEvent(new Event('clearNotification'));
    }, 3000); // 3000ms = 3 seconds

    // Cleanup the timer if the component unmounts or if message changes
    return () => clearTimeout(timer);
  }, [message]);

  if (!message) return null; // Early return if there's no message

  return <div className={`search-notification ${type}`}>{message}</div>;
};

export default Notification;
